import fetch from '../fetch'

const GoodsService = {
	/**
	 * @description 修改关键词关联产品
	 * @returns {*}
	 * @param params
	 */
	updateKeywordRelationProduct(params = {}) {
		return fetch('/v2/goods/bms/keyword/goods_relation_update_product', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 修改关键词分组基本信息
	 * @returns {*}
	 * @param params
	 */
	updateKeywordGroup(params = {}) {
		return fetch('/v2/goods/bms/keyword/update_group', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 修改分组关键词
	 * @returns {*}
	 * @param params
	 */
	updateKeywordItem(params = {}) {
		return fetch('/v2/goods/bms/keyword/group_update_keyword_item', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 删除关键词
	 * @returns {*}
	 * @param id
	 */
	getGroupKeywordDetail(id = '') {
		return fetch('/v2/goods/bms/keyword/get_keyword_group_detail', {
			params: { id }
		})
	},

	/**
	 * @description 添加关键词
	 * @param params
	 * @returns {*}
	 */
	addGroupKeyword(params = {}) {
		return fetch('/v2/goods/bms/keyword/add_group', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 关键词模糊查询
	 * @param params
	 * @returns {*}
	 */
	getKeywordSearchProduct(params = {}) {
		return fetch('/v2/goods/bms/keyword/search_product', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 关键词列表
	 * @param params
	 * @returns {*}
	 */
	getKeywordGroupList(params = {}) {
		return fetch('/v2/goods/bms/keyword/get_keyword_group_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 下载任务
	 * @returns {*}
	 * @param registerOrderId
	 */
	createWaitApplyInfo(registerOrderId = '') {
		return fetch(`/v2/goods/bms/campaign/create_wait_apply_info`, {
			method: 'POST',
			data: { registerOrderId }
		})
	},

	/**
	 * @description 下载任务
	 * @param campaignId
	 * @returns {*}
	 */
	downloadClassInfo(campaignId = '') {
		return fetch(`/v1/goods/bms/download_class_info/${campaignId}`, {
			method: 'POST',
			data: { campaignId }
		})
	},

	/**
	 * @desc 获取商品列表
	 * @param data
	 * @returns {Promise}
	 */
	getGoodsList(data) {
		return fetch('/v2/goods/bms/book_manager_list', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 更新商品上下架
	 * @param data
	 * @returns {Promise}
	 */
	updateGoodsStatus(data) {
		return fetch('/v2/goods/bms/update_book_sell_status', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 根据isbn获取书本详情
	 * @param data
	 * @returns {Promise}
	 */
	getBookByIsbn(data) {
		return fetch('/v1/goods/bms/getBookByIsbn', {
			params: { ...data }
		})
	},

	/**
	 * @desc 新增绘本
	 * @param data
	 * @returns {Promise}
	 */
	addGoods(data) {
		return fetch('/v1/goods/bms/addBook', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 获取绘本信息
	 * @returns {Promise}
	 * @param bookId
	 */
	getBookInfo(bookId = '') {
		return fetch('/v2/goods/bms/get_book_info', {
			params: { bookId }
		})
	},

	/**
	 * @desc 更新绘本详情
	 * @param data
	 * @returns {Promise}
	 */
	updateGoods(data) {
		return fetch('/v2/goods/bms/update_book', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 商品入库
	 * @param data
	 * @returns {Promise}
	 */
	inStock(data) {
		return fetch('/v1/goods/bms/in_stock', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 锁定/释放库存
	 * @param data
	 * @returns {Promise}
	 */
	lockOrRelease(data) {
		return fetch('/v1/goods/bms/lockOrRelease', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 获取绘本关联的音频信息
	 * @param bookId
	 * @returns {Promise}
	 */
	getAudioInfo(bookId = '') {
		return fetch(`/v1/goods/bms/book/related/book_audios/${bookId}`)
	},

	/**
	 * @desc 获取绘本关联的解析信息
	 * @param bookId
	 * @returns {Promise}
	 */
	getContextInfo(bookId = '') {
		return fetch(`/v1/goods/bms/book/related/book_contents/${bookId}`)
	},

	/**
	 * @desc 新增绘本关联的解析信息
	 * @param data
	 * @returns {Promise}
	 */
	addContextInfo(data) {
		return fetch('/v1/goods/bms/book/related/add_book_context', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 更新绘本关联的解析信息
	 * @param data
	 * @returns {Promise}
	 */
	updateContextInfo(data) {
		return fetch('/v1/goods/bms/book/related/upt_book_context', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @description 添加绘本音频信息
	 * @param params
	 * @returns {*}
	 */
	addBookAudio(params = {}) {
		return fetch('/v1/goods/bms/book/related/add_book_audio', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新绘本音频信息
	 * @param params
	 * @returns {*}
	 */
	updateBookAudio(params = {}) {
		return fetch('/v1/goods/bms/book/related/upt_book_audio', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 删除绘本关联的解析信息
	 * @param contextId
	 * @returns {Promise}
	 */
	delContextInfo(contextId = '') {
		return fetch(`/v1/goods/bms/book/related/del_book_context/${contextId}`, {
			method: 'POST'
		})
	},

	/**
	 * @desc 获取课程列表
	 * @param params
	 * @returns {Promise}
	 */
	getCourseList(params) {
		return fetch('/v1/goods/bms/course/list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取每周一颗详情
	 * @param {string} [id=""]
	 * @returns
	 */
	getCourseInfo(id = '') {
		return fetch(`/v1/goods/bms/course/${id}/detail`)
	},

	/**
	 * @desc 查询班级用户列表
	 * @param {string} [classInfoId='']
	 * @returns
	 */
	getCourseUserList(classInfoId = '') {
		return fetch(`/v1/goods/bms/course/${classInfoId}/user_list`)
	},

	/**
	 * @desc 查询学院课程的班级列表V2
	 * @param {*} [params={}]
	 * @returns
	 */
	getCourseUserListV2(params = {}) {
		return fetch(`/v1/goods/bms/course/user_list_v2`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 查询学院课程的班级列表
	 * @param {number} [courseId=1]
	 * @param {string} [phone='']
	 * @returns
	 */
	getClassListOfCourse(courseId = 1, phone = '') {
		return fetch(`/v1/goods/bms/course/class_list`, {
			params: { courseId, phone }
		})
	},

	/**
	 * @desc 获取班级用户详情
	 * @param {string} [classInfoId='']
	 * @returns
	 */
	getUserClassDetail(classInfoId = '') {
		return fetch(`/v1/goods/bms/course/${classInfoId}/user_class_detail`)
	},

	/**
	 * @desc 审核报名信息
	 * @param {*} [params={}]
	 * @returns
	 */
	applyAuditOperation(params = {}) {
		return fetch(`/v1/goods/bms/course/verify_user_class_submission`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取可清楚缓存列表
	 * @returns
	 */
	getClearCacheList() {
		return fetch('/v1/goods/bms/other/clear_cache_list')
	},

	/**
	 * @desc 批量清除缓存
	 * @param {*} [{ db = '', keyPre = '' }={}]
	 * @returns
	 */
	batchClearCache({ db = '', keyPre = '' } = {}) {
		return fetch('/v1/goods/bms/other/batch_clear_cache', {
			params: { db, keyPre }
		})
	},

	/**
	 * @desc 刷新绘本ES
	 * @returns
	 */
	refreshBookEs() {
		return fetch('/v1/goods/bms/other/refresh_book_es')
	},

	sendScoreMsg({ classInfoId, type } = {}) {
		return fetch(`/v1/goods/bms/course/send_score_msg/${classInfoId}`, {
			method: 'POST',
			data: { classInfoId, type }
		})
	},

	/**
	 * @desc 查询rfid 记录
	 * @param {*} [params={}]
	 * @returns
	 */
	getRfidRecordList(params = {}) {
		return fetch('/v2/goods/bms/rfid_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取可换班级列表
	 * @returns
	 */
	getValidClassInfoList(campaignId = '') {
		return fetch('/v1/goods/bms/valid_class_info_list', {
			params: { campaignId }
		})
	},

	/**
	 * @desc 更换用户班级
	 * @param {*} [params={}]
	 * @returns
	 */
	changeUserClass(params = {}) {
		return fetch('/v1/goods/bms/change_user_class', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 新增班级
	 * @param {*} [params={}]
	 * @returns
	 */
	addClassInfo(params = {}) {
		return fetch('/v1/goods/bms/course/add_class_info', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @dec 获取训练营列表
	 * @param params
	 * @returns
	 */
	get2680CampList(params = {}) {
		return fetch('/v1/goods/bms/camp_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 根据ID 获取训练营用户列表
	 * @param params
	 * @returns
	 */
	getCampClassInfoList(params = {}) {
		return fetch('/v1/goods/bms/camp_class_info_list', {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @desc 获取2680口试列表
	 * @param params
	 * @returns
	 */
	getCampExamList(params = {}) {
		return fetch('/v1/goods/bms/camp_class_exam_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 提交口语评分
	 * @param params
	 * @returns
	 */
	commitUserExamResult(params) {
		return fetch('/v1/goods/bms/commit_user_exam_result', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新2680报名资料状态
	 * @param params
	 * @returns
	 */
	updateCampApplyInfo(params = {}) {
		return fetch('/v1/goods/bms/check_camp_data', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取2680报名资料详情
	 * @param id
	 * @returns
	 */
	getCampDataDetail(id = '') {
		return fetch('/v1/goods/bms/get_camp_data_detail', {
			params: { id }
		})
	},
	/**
	 * @description 更新报名资料详情
	 * @param {*} params
	 */
	updateCampDataDetail(params = {}) {
		return fetch('/v1/goods/bms/camp_data_detail_update', {
			params: { ...params }
		})
	},
	/**
	 * @description 获取camp配置中的课程详情
	 */
	getCampCourseDetail(courseId = '') {
		return fetch('/v1/goods/camp_config/get_course_detail', {
			params: { courseId }
		})
	},

	/**
	 * @desc
	 * @param params
	 * @returns
	 */
	getCampCourseList(params = {}) {
		return fetch('/v1/goods/camp_config/course_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取子课程列表
	 * @param params
	 * @returns
	 */
	getSubCourseList(params = {}) {
		return fetch('/v1/goods/camp_config/sub_course_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取camp对应的leader的列表
	 * @param id
	 * @returns
	 */
	getCampClassLeaderList(id = '') {
		return fetch('/v1/goods/camp_config/camp_class_leader_list', {
			params: { id }
		})
	},

	/**
	 * @desc 创建训练营主课程
	 * @param params
	 * @returns
	 */
	createCampCourse(params = {}) {
		return fetch('/v1/goods/camp_config/create_course', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新训练营主课程
	 * @param {*} params
	 */
	updateCampCourse(params = {}) {
		return fetch('/v1/goods/camp_config/update_course', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 创建训练营
	 * @param params
	 * @returns
	 */
	createCampNormal(params = {}) {
		return fetch('/v1/goods/camp_config/create_camp_normal', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新训练营
	 * @param {*} params
	 */
	updateCampNormal(params = {}) {
		return fetch('/v1/goods/camp_config/edit_camp_normal', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取camp详情
	 * @param {*} campId
	 */
	getCampNormalDetail(campId = '') {
		return fetch('/v1/goods/camp_config/get_camp_detail', {
			params: { campId }
		})
	},

	/**
	 * @desc 获取所有的训练营列表
	 * @param params
	 * @returns {undefined}
	 */
	getCampList(params = {}) {
		return fetch('/v1/goods/camp_config/camp_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取leader下的班级列表
	 * @param params
	 * @returns {undefined}
	 */
	getCampLeaderClassList(params = {}) {
		return fetch('/v1/goods/camp_config/get_camp_leader_class_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 新增Leader下的班级
	 * @param params
	 * @returns {undefined}
	 */
	addCampLeaderClass(params = {}) {
		return fetch('/v1/goods/camp_config/add_camp_leader_class', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 新增camp leaders
	 * @param params
	 * @returns {undefined}
	 */
	addCampLeaders(params = {}) {
		return fetch('/v1/goods/camp_config/add_camp_leader', {
			params: { ...params }
		})
	},

	/**
	 * @desc 创建每周一课
	 * @param {*} [params={}]
	 * @returns
	 */
	createWeekCourse(params = {}) {
		return fetch('/v1/goods/bms/course/create', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新每周一课
	 * @param {*} [params={}]
	 * @returns
	 */
	updateWeekCourse(params = {}) {
		return fetch('/v1/goods/bms/course/update', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 新增每周一课的音频
	 * @param {*} [params={}]
	 * @returns
	 */
	addWeekCourseAudio(params = {}) {
		return fetch('/v1/goods/bms/course/audio/add', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新每周一课的音频
	 * @param {*} [params={}]
	 * @returns
	 */
	updateWeekCourseAudio(params = {}) {
		return fetch('/v1/goods/bms/course/audio/update', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 创建camp相关主课程的子课程
	 * @param {*} params
	 */
	createCampSubCourse(params = {}) {
		return fetch('/v1/goods/camp_config/create_sub_course', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新camp相关主课程的子课程
	 * @param {*} params
	 */
	updateCampSubCourse(params = {}) {
		return fetch('/v1/goods/camp_config/update_sub_course', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新用户考试音频
	 * @param params
	 * @returns {*}
	 */
	uploadExamAudio(params = {}) {
		return fetch('/v1/goods/bms/udpate_exam_info', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取banner列表
	 * @param params
	 * @returns {*}
	 */
	getBmsBannerList(params = {}) {
		return fetch('/v1/goods/bms/get_bms_banner_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 添加/更新banner配置
	 * @param params
	 * @returns {*}
	 */
	editBmsBanner(params = {}) {
		return fetch('/v1/goods/bms/edit_banner', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取channel列表
	 * @param params
	 * @returns {*}
	 */
	getBmsChannelList(params) {
		return fetch('/v1/goods/bms/get_bms_channel_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 添加/更新banner配置
	 * @param params
	 * @returns {*}
	 */
	editBmsChannel(params = {}) {
		return fetch('/v1/goods/bms/edit_channel', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取首页磁贴列表
	 * @returns
	 */
	getBmsBlockList(params = {}) {
		return fetch('/v1/goods/bms/get_bms_block_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 编辑磁贴
	 * @param {*} [params={}]
	 * @returns
	 */
	editHomeBlock(params = {}) {
		return fetch('/v1/goods/bms/edit_block', {
			method: 'POST',
			data: { ...params }
		})
	},

	/***
	 * @desc 获取全部招募信息列表
	 * @param params
	 * @returns {*}
	 */
	getCampEnlistList(params = {}) {
		return fetch('/v1/goods/bms/assistant/get_camp_enlist_list', {
			params: { ...params }
		})
	},

	/***
	 * @desc 根据id获取招募信息
	 * @param id
	 * @returns {*}
	 */
	getGetCampEnlistInfo(id = {}) {
		return fetch('/v1/goods//bms/assistant/get_camp_enlist_info', {
			params: { id }
		})
	},

	/***
	 * @desc 招募中的列表
	 * @param params
	 * @returns {*}
	 */
	getCampApplyList(params = {}) {
		return fetch('/v1/goods/bms/assistant/get_camp_apply_list', {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @description 根据ID查询某一期训练营信息
	 * @param campId
	 * @returns {*}
	 */
	getCampInfoById(campId = '') {
		return fetch('/v1/goods/bms/get_camp_info', {
			params: { campId }
		})
	},

	/**
	 * @desc 提交班委审核结果
	 * @param params
	 * @returns {*}
	 */
	checkAssistantApply(params = {}) {
		return fetch('/v1/goods/bms/assistant/check_assistant_apply_ch', {
			params: { ...params }
		})
	},

	/***
	 * @desc 新建招募
	 * @param params
	 * @returns {*}
	 */
	addCampAssistantEnlist(params = {}) {
		return fetch('/v1/goods/bms/assistant/add_camp_assistant_enlist', {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @desc 编辑招募
	 * @param params
	 * @returns {*}
	 */
	updateCampAssistantEnlist(params = {}) {
		return fetch('/v1/goods/bms/assistant/update_camp_assistant_enlist', {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @description 创建一期训练营
	 * @param params
	 * @returns {*}
	 */
	createCampInfo(params = {}) {
		return fetch('/v1/goods/bms/create_camp_info', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新一期训练营
	 * @param params
	 * @returns {*}
	 */
	updateCampInfo(params = {}) {
		return fetch('/v1/goods/bms/update_camp_info', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取招募完成列表
	 * @param enlistId
	 * @returns {*}
	 */
	getOverCampApplyList(enlistId = '') {
		return fetch('/v1/goods/bms/assistant/get_over_camp_apply_list', {
			params: { enlistId }
		})
	},

	/**
	 * @description
	 * @param params
	 * @returns {*}
	 */
	queryNeedCheckList(params = {}) {
		return fetch('/v1/goods/bms/assistant/query_need_check_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 查询所有招募完成班委接口
	 * @param params
	 * @returns {*}
	 */
	queryAllPassList(params = {}) {
		return fetch('/v1/goods/bms/assistant/query_all_pass_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 根据Id查询班委详情
	 * @param id
	 * @returns {*}
	 */
	queryAssistantDetail(id = '') {
		return fetch('/v1/goods/bms/assistant/qusery_assistant_detail', {
			params: { id }
		})
	},

	/**
	 * @description 审核带班申请
	 * @param params
	 * @returns {*}
	 */
	checkAssistant(params = {}) {
		return fetch('/v1/goods/bms/assistant/check_assistant', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 查询可用身份
	 * @param params
	 * @returns {*}
	 */
	queryCampAssistantSelect(params = {}) {
		return fetch('/v1/goods/bms/assistant/query_camp_assistant_select', {
			params: { ...params }
		})
	},

	/**
	 * @description 查询可选班主任
	 * @param campId
	 * @returns {*}
	 */
	queryCampTeacher(campId = '') {
		return fetch('/v1/goods/bms/assistant/query_camp_teacher', {
			params: { campId }
		})
	},

	/**
	 * @description 编辑班主任
	 * @param params
	 * @returns {*}
	 */
	editCampTeacher(params = {}) {
		return fetch('/v1/goods/bms/assistant/edit_camp_teacher', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取子课程管理列表-训练营列表
	 * @param params
	 * @returns {*}
	 */
	getCampaignCourseListInSubCourseManagement(params) {
		return fetch('/v1/goods/bms/campaign_course/campaign_course_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取子课程管理列表-子课程列表
	 * @param courseId
	 * @returns {*}
	 */
	getCampaignSubCourseList(courseId) {
		return fetch(
			`/v1/goods/bms/campaign_course/campaign_sub_course_list/${courseId}`
		)
	},

	/**
	 * @description 上架子课程状态
	 * @param subCourseId
	 * @returns {*}
	 */
	onShelfSubCourseFile(subCourseId) {
		return fetch(
			`/v1/goods/bms/campaign_course/on_shelf_sub_course_file/${subCourseId}`
		)
	},

	/**
	 * @description 获取录课详情
	 * @param subCourseId
	 * @returns {*}
	 */
	getCourseFileRecordList(subCourseId) {
		return fetch(
			`/v1/goods/crm/campaign/course_file_record_list/${subCourseId}`
		)
	},

	/**
	 * @description 更新录课的内容
	 * @param params
	 * @returns {*}
	 */
	updateSubCourseFileContext(params = {}) {
		return fetch(
			'/v1/goods/bms/campaign_course/sub_course_file_update_context',
			{
				method: 'POST',
				data: { ...params }
			}
		)
	},

	/**
	 * @description 更新录课排序
	 * @param params
	 * @returns {*}
	 */
	updateSubCourseFileDisplay(params = {}) {
		return fetch(
			'/v1/goods/bms/campaign_course/sub_course_file_update_display',
			{
				method: 'POST',
				data: { ...params }
			}
		)
	},

	/**
	 * @description 删除录课记录
	 * @param subCourseFileId
	 * @returns {*}
	 */
	deleteSubCourseFile(subCourseFileId) {
		return fetch(
			`/v1/goods/bms/campaign_course/delete_sub_course_file/${subCourseFileId}`,
			{
				method: 'POST'
			}
		)
	},

	/**
	 * @description 获取课程对应阶段列表
	 * @param courseId
	 * @returns {*}
	 */
	getCampaignSubCourseStage(courseId) {
		return fetch(
			`/v1/goods/bms/campaign_course/get_campaign_sub_course_stage/${courseId}`
		)
	},

	/**
	 * @description 创建子课并且绑定老师
	 * @param params
	 * @returns {*}
	 */
	addCampaignSubCourse(params = {}) {
		return fetch('/v1/goods/bms/campaign_course/add_campaign_sub_course', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 选择老师指定录课
	 * @param params
	 * @returns {*}
	 */
	selectCampaignSubCourseAuthor(params = {}) {
		return fetch(
			'/v1/goods/bms/campaign_course/select_campaign_sub_course_author',
			{
				method: 'POST',
				data: { ...params }
			}
		)
	},

	/**
	 * @desc 更新训练营子课列表
	 * @param params
	 * @returns {*}
	 */
	updateSubCourseFileList(params = {}) {
		return fetch(`/v1/goods/bms/campaign_course/update_sub_course_file_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取物理页
	 * @param params
	 * @returns {*}
	 */
	getBookPageMapping(params = {}) {
		return fetch(`/v2/goods/bms/book/page/get_book_page_mapping`, {
			params: { ...params }
		})
	},

	/**
	 * @description 更新物理页信息
	 * @param params
	 * @returns {*}
	 */
	updateBookPage(params = {}) {
		return fetch(`/v2/goods/bms/book/page/update_book_page`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新物理页图片
	 * @param params
	 * @returns {*}
	 */
	upLoadPage(params = {}) {
		return fetch(`/v2/goods/bms/book/page/upload_page`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 刷新物理页
	 * @param bookId
	 * @returns {*}
	 */
	refreshPage(bookId = '') {
		return fetch(`/v2/goods/bms/book/page/re_flash_page/${bookId}`)
	},

	/**
	 * @description 刷新内容页
	 * @param params
	 * @returns {*}
	 */
	refreshPagination(params = {}, bookId = '') {
		return fetch(`/v2/goods/bms/book/page/re_flash_pagination/${bookId}`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取绘本音频信息
	 * @param params
	 * @returns {*}
	 */
	getBookPageVoice(params = {}) {
		return fetch(`/v2/goods/bms/book/page/get_book_page_voice`, {
			params: { ...params }
		})
	},

	/**
	 * @description 更新绘本音频信息
	 * @param params
	 * @returns {*}
	 */
	updateBookPageVoice(params = {}) {
		return fetch(`/v2/goods/bms/book/page/update_book_page_voice`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 上传绘本音频信息
	 * @param params
	 * @returns {*}
	 */
	upLoadPageVoice(params = {}) {
		return fetch(`/v2/goods/bms/book/page/upload_page_voice`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取绘本映射列表
	 * @param bookId
	 * @returns {*}
	 */
	getBookMappingList(params = {}) {
		return fetch(`/v2/goods/bms/book/page/get_book_mapping`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 删除当前绘本映射
	 * @param bookId
	 * @param baseBookId
	 * @returns {*}
	 */
	deleteBookMapping(bookId = '', baseBookId = '') {
		return fetch(
			`/v2/goods/bms/book/page/delete_book_mapping/${bookId}/${baseBookId}`
		)
	},

	/**
	 *
	 * @param params 更新绘本映射
	 * @returns {*}
	 */
	updateBookMapping(params = {}) {
		return fetch(`/v2/goods/bms/book/page/book_mapping`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 补考口试列表
	 * @param params
	 * @returns {*}
	 */
	getMakeUpList(params = {}) {
		return fetch(`/v1/goods/bms/make_up_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 口试审核
	 * @param params
	 * @returns {*}
	 */
	getMakeUpExamList(params = {}) {
		return fetch(`/v1/goods/bms/make_up_exam_class_exam_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 上传质检视频
	 * @param params
	 * @returns {*}
	 */
	uploadCheckVideo(params = {}) {
		return fetch(`/v1/goods/bms/upload_check_video`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取banner列表V2
	 * @param params
	 * @returns {*}
	 */
	getBmsBannerListV2(params = {}) {
		return fetch(`/v2/goods/banner/get_bms_banner_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 新增/修改banner v2
	 * @param params
	 * @returns {*}
	 */
	editBannerV2(params = {}) {
		return fetch(`/v2/goods/banner/edit_banner`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取所有书单接口
	 * @param params
	 * @returns {*}
	 */
	getAllBookList(params = {}) {
		return fetch(`/v2/goods/tag/get_all_book_list_by_key_word`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取所有一级标签
	 * @param params
	 * @returns {*}
	 */
	getFirstAllLabel(params = {}) {
		return fetch(`/v2/goods/tag/get_all_first_label_by_key_word`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/***
	 * @description 根据id查二级标签
	 * @param params
	 * @returns {*}
	 */
	getSecondAllLabel(params = {}) {
		return fetch(`/v2/goods/tag/get_second_label_by_ids`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 根据id获取一级标签名字
	 * @param params
	 * @returns {*}
	 */
	getFirstTagInfo(params = {}) {
		return fetch(`/v2/goods/tag/get_first_label_by_ids`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 根据id获取书单名字
	 * @param params
	 * @returns {*}
	 */
	getBookListInfo(params = {}) {
		return fetch(`/v2/goods/tag/get_book_list_name_by_ids`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 根据id获取书单名字
	 * @param params
	 * @returns {*}
	 */
	getSecondTagInfo(params = {}) {
		return fetch(`/v2/goods/tag/get_second_label_name_by_ids`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取谷兜兜设备详情
	 * @param deviceManagerId
	 * @returns {*}
	 */
	getGddDetail(deviceManagerId = '') {
		return fetch(`/v2/goods/bms/device/gdd_detail/${deviceManagerId}`)
	},

	/**
	 * @description 创作家文章根据id获取标签年龄
	 * @param params
	 * @returns {*}
	 */
	getBookMinAndMaxAge(params = {}) {
		return fetch(`/v1/goods/bms/book/page/get_book_min_and_max_age`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取一级标签下所有的二级标签配置
	 * @param params
	 * @returns {*}
	 */
	getAllSecondTagByAge(params = {}) {
		return fetch(`/v2/goods/tag/get_all_second_tag_by_age`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取以及标签下所有的二级标签配置
	 * @param params
	 * @returns {*}
	 */
	getSecondTagByAge(params = {}) {
		return fetch(`/v2/goods/tag/get_second_tag_by_age`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 保存标签下所有的二级标签配置
	 * @param params
	 * @returns {*}
	 */
	saveSecondTagByAge(params = {}) {
		return fetch(`/v2/goods/tag/save_second_tag_by_age`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取当前商品标签
	 * @param bookId
	 * @returns {*}
	 */
	getBookTagList(bookId = '') {
		return fetch(`/v2/goods/tag/get_book_tag_list`, {
			params: { bookId }
		})
	},

	/**
	 * @description 查询所有商品标签
	 * @param keyword
	 * @returns {*}
	 */
	getAllGoodTagList(keyword = '') {
		return fetch(`/v2/goods/tag/get_goods_tag_list`, {
			method: 'POST',
			data: { keyword }
		})
	},

	/**
	 * @description 中英文绘本映射列表
	 * @param params
	 * @returns {*}
	 */
	getBookEditionMappingList(params = {}) {
		return fetch(`/v2/goods/bms/book/page/book_edition_mapping/query`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取绘本对比列表（玩瞳、中英文）
	 * @param params
	 * @returns {*}
	 */
	getBookPageCompareList(params = {}) {
		return fetch(`/v2/goods/bms/book/page/book_page/compare`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加作者
	 * @param params
	 * @returns {*}
	 */
	addGoodsAuthor(params = {}) {
		return fetch(`/v2/goods/bms/add_author`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加出版社
	 * @param params
	 * @returns {*}
	 */
	addGoodsPublisher(params = {}) {
		return fetch(`/v2/goods/bms/add_publisher_info`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 修改作者
	 * @param params
	 * @returns {*}
	 */
	editGoodsAuthor(params = {}) {
		return fetch(`/v2/goods/bms/edit_author`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 修改出版社
	 * @param params
	 * @returns {*}
	 */
	editGoodsPublisher(params = {}) {
		return fetch(`/v2/goods/bms/edit_publisher_info`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 作者详情
	 * @returns {*}
	 * @param id
	 */
	getGoodsAuthorDetail(id = '') {
		return fetch(`/v2/goods/bms/get_author_detail`, {
			params: { id }
		})
	},

	/**
	 * @description 出版社详情
	 * @returns {*}
	 * @param id
	 */
	getGoodsPublisherDetail(id = '') {
		return fetch(`/v2/goods/bms/get_publisher_info_detail`, {
			params: { id }
		})
	},

	/**
	 * @description 作者列表
	 * @param params
	 * @returns {*}
	 */
	getAuthorList(params = {}) {
		return fetch(`/v2/goods/bms/get_author_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 出版社列表
	 * @param params
	 * @returns {*}
	 */
	getPublisherList(params = {}) {
		return fetch(`/v2/goods/bms/get_publisher_info_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取商品一二级标签
	 * @param params
	 * @returns {*}
	 */
	getGoodsTagList(params = {}) {
		return fetch(`/v2/goods/bms/get_goods_tag_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取关联标签列表
	 * @param params
	 * @returns {*}
	 */
	getGoodsRelationTagList(params = {}) {
		return fetch(`/v2/goods/bms/get_first_tag_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 标签详情
	 * @param tagId
	 * @returns {*}
	 */
	getGoodsTagDetail(tagId = '') {
		return fetch(`/v2/goods/bms/get_goods_tag_detail`, {
			params: { tagId }
		})
	},

	/**
	 * @description 更新关联标签
	 * @param params
	 * @returns {*}
	 */
	updateFirstTagRelation(params = {}) {
		return fetch(`/v2/goods/bms/update_first_tag_relation`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新标签
	 * @param params
	 * @returns {*}
	 */
	updateGoodsTag(params = {}) {
		return fetch(`/v2/goods/bms/update_goods_tag`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 新增标签
	 * @param params
	 * @returns {*}
	 */
	addGoodsTag(params = {}) {
		return fetch(`/v2/goods/bms/add_goods_tag`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 删除标签
	 * @param tagId
	 * @returns {*}
	 */
	deleteGoodTag(tagId = '') {
		return fetch(`/v2/goods/bms/del_goods_tag`, {
			params: { tagId }
		})
	},

	/**
	 * @description 获取书单列表
	 * @param params
	 * @returns {*}
	 */
	getGoodQueryBookList(params = {}) {
		return fetch(`/v2/goods/bms/book_list/query_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取书单列表
	 * @param params
	 * @returns {*}
	 */
	addBookList(params = {}) {
		return fetch(`/v2/goods/bms/book_list/add`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新书单列表
	 * @param params
	 * @returns {*}
	 */
	updateBookList(params = {}) {
		return fetch(`/v2/goods/bms/book_list/update`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新书单关联列表
	 * @param params
	 * @returns {*}
	 */
	updateUnionBookList(params = {}) {
		return fetch(`/v2/goods/bms/book_list/update_union_book`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 书单详情
	 * @param params
	 * @returns {*}
	 */
	getBookListDetail(params = {}) {
		return fetch(`/v2/goods/bms/book_list/detail`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取书单关联详情
	 * @param params
	 * @returns {*}
	 */
	getUnionDetail(params = {}) {
		return fetch(`/v2/goods/bms/book_list/union_book_detail`, {
			params: { ...params }
		})
	},

	/**
	 * @description 更换正价课分组
	 * @param params
	 * @returns {*}
	 */
	changeCampClass(params = {}) {
		return fetch('/v1/goods/bms/change_camp_class', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取班级分组列表
	 * @param campaignId
	 * @returns {*}
	 */
	getCampaignClassList(campaignId = '') {
		return fetch('/v1/goods/bms/get_campaign_class_list', {
			params: { campaignId }
		})
	},

	/**
	 * @description 销售商品 新增课程
	 * @param params
	 * @returns {*}
	 */
	getCampaignList(params = {}) {
		return fetch('/v2/goods/bms/campaign/list', {
			method: 'POST',
			data: { ...params }
		})
	}
}

export default GoodsService
