import { observable, action } from 'mobx'

class Common {
  @observable staffInfo = {}
  @observable token = ''
  @observable collapsed = false
  @observable loading = false
  @observable selectedKeys = []
  @observable secondSelectedKeys = []
  @observable bagOrderCode = ''
  @observable orderCode = ''
  @observable menuList = []
  @observable btnAuthList = []
  @observable openKeys = []
  @observable cityOption = []
  @observable newAsideMenu = []
  @observable tabList = []
  @observable orderUserInfo = ''
  @observable tabValue = ''
  @observable userTabValue = ''
  @observable sessionSearchForm = {}
  @observable userTabList = []

  @action.bound
  onCollapse() {
    this.collapsed = !this.collapsed
  }

  @action.bound
  logout() {
    this.token = ''
  }

  @action.bound
  showLoading() {
    this.loading = true
  }

  @action.bound
  hideLoading() {
    this.loading = false
  }

  @action.bound
  updateToken(token = '') {
    this.token = token || ''
  }

  @action.bound
  updateSelectedKeys(key = '') {
    this.selectedKeys = [key]
  }

  @action.bound
  updateSecondSelectedKeys(key = '') {
    this.secondSelectedKeys = [key]
  }

  @action.bound
  updateOpenKeys(keys = []) {
    this.openKeys = keys
  }

  @action.bound
  updateBagOrderCode(code = '') {
    this.bagOrderCode = code || ''
  }

  @action.bound
  updateOrderCode(code = '') {
    this.orderCode = code || ''
  }

  @action.bound
  updateMenuList(list = []) {
    this.menuList = list
  }

  @action.bound
  updateBtnAuth(list = []) {
    this.btnAuthList = list
  }

  @action.bound
  updateCityOption(list = []) {
    this.cityOption = list
  }

  @action.bound
  updateOrderStatus(info = '') {
    this.orderUserInfo = info
  }

  @action.bound
  updateNewAsideMenu(list = []) {
    this.newAsideMenu = list
  }

  @action.bound
  updateTabList(item = {}) {
    if (this.tabList.length >= 2) {
      this.tabList.splice(1, 0, item)
    } else {
      this.tabList.push(item)
    }
  }

  @action.bound
  updateUserTabList(item = {}) {
    if (this.userTabList.length >= 2) {
      this.userTabList.splice(1, 0, item)
    } else {
      this.userTabList.push(item)
    }
  }

  @action.bound
  changeUserTabList(list = []) {
    this.userTabList = list
  }

  @action.bound
  updateTabValue(value = '') {
    this.tabValue = value
  }

  @action.bound
  updateUserTabValue(value = '') {
    this.userTabValue = value
  }

  @action.bound
  updateSessionSearchForm(value = {}) {
    this.sessionSearchForm = value
  }

  @action.bound
  clearTabList() {
    this.tabList = []
  }

  @action.bound
  updateCurrentTab(value = '') {
    this.currentTab = ''
  }
}

const CommonStore = new Common()

export { CommonStore }
