import { observable, action } from 'mobx'
import moment from 'moment'
import { Button } from 'antd'
import { TIME_FORMAT } from '../../libs/conf'
import React from 'react'

class unusualStore {
  @observable forwardColumns = [
    {
      title: '工单ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '用户信息',
      dataIndex: 'userName',
      key: 'userName',
      render: (text, record) => {
        const { userTelephone } = record
        return `${text || ''} / ${userTelephone || ''}`
      }
    },
    {
      title: '超时天数',
      dataIndex: 'dueDays',
      key: 'dueDays',
      sorter: true
    },
    {
      title: '订单编号',
      dataIndex: 'orderCode',
      key: 'orderCode'
    },
    {
      title: '书袋序号',
      dataIndex: 'sequence',
      key: 'sequence'
    },
    {
      title: '快递公司',
      dataIndex: 'trackingName',
      key: 'trackingName'
    },
    {
      title: '物流单号',
      dataIndex: 'trackingNo',
      key: 'trackingNo'
    },
    {
      title: '状态更新时间',
      dataIndex: 'ticketRecordUpdateTime',
      key: 'ticketRecordUpdateTime',
      render: (text) => {
        return moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '工单记录内容',
      dataIndex: 'ticketRecordContent',
      key: 'ticketRecordContent',
      return: (text) => {
        return text || ''
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => {
        return <Button type={`link`} onClick={() => this.showDetail(record)}>详情</Button>
      }
    }
  ]
  @observable reverseOvertimeColumns = [
    {
      title: '工单ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '用户信息',
      dataIndex: 'userName',
      key: 'userName',
      render: (text, record) => {
        const { userTelephone } = record
        return `${text || ''} / ${userTelephone || ''}`
      }
    },
    {
      title: '超时天数',
      dataIndex: 'dueDays',
      key: 'dueDays'
    },
    {
      title: '订单编号',
      dataIndex: 'orderCode',
      key: 'orderCode'
    },
    {
      title: '取件时间',
      dataIndex: 'pickTime',
      key: 'pickTime',
      render: (text) => {
        return moment(text).format(TIME_FORMAT.FULL)
      }
    },
    {
      title: '快递公司',
      dataIndex: 'trackingName',
      key: 'trackingName'
    },
    {
      title: '物流单号',
      dataIndex: 'trackingNo',
      key: 'trackingNo'
    },
    {
      title: '状态更新时间',
      dataIndex: 'ticketRecordUpdateTime',
      key: 'ticketRecordUpdateTime',
      render: (text) => {
        return moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '工单记录内容',
      dataIndex: 'ticketRecordContent',
      key: 'ticketRecordContent',
      return: (text) => {
        return text || ''
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => {
        return <Button type={`link`} onClick={() => this.showDetail(record)}>详情</Button>
      }
    }
  ]
  @observable reverseStorageColumns = [
    {
      title: '工单ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '用户信息',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (text, record) => {
        const { phone } = record
        return `${text || ''} / ${phone || ''}`
      }
    },
    {
      title: '已发货天数',
      dataIndex: 'shippedDate',
      key: 'shippedDate'
    },
    {
      title: '订单编号',
      dataIndex: 'orderCode',
      key: 'orderCode'
    },
    {
      title: '发货时间',
      dataIndex: 'shippedTime',
      key: 'shippedTime',
      render: (text) => {
        return moment(text).format(TIME_FORMAT.FULL)
      }
    },
    {
      title: '快递公司',
      dataIndex: 'express',
      key: 'express'
    },
    {
      title: '物流单号',
      dataIndex: 'expressCode',
      key: 'expressCode'
    },
    {
      title: '状态更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      render: (text) => {
        return moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => {
        return <Button type={`link`} onClick={() => this.showDetail(record)}>详情</Button>
      }
    }
  ]
  @observable reverseCancelColumns = [
    {
      title: '工单ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '用户信息',
      dataIndex: 'userName',
      key: 'userName',
      render: (text, record) => {
        const { userTelephone } = record
        return `${text || ''} / ${userTelephone || ''}`
      }
    },

    {
      title: '订单编号',
      dataIndex: 'orderCode',
      key: 'orderCode'
    },
    {
      title: '书袋序号',
      dataIndex: 'sequence',
      key: 'sequence'
    },
    {
      title: '预约取件时间',
      dataIndex: 'appointmentTime',
      key: 'appointmentTime',
      render: (text) => {
        return moment(text).format(TIME_FORMAT.FULL)
      }
    },
    {
      title: '快递员取消时间',
      dataIndex: 'cancelTime',
      key: 'cancelTime',
      render: (text) => {
        return moment(text).format(TIME_FORMAT.FULL)
      }
    },
    {
      title: '取消前物流单号',
      dataIndex: 'expressCode',
      key: 'expressCode'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => {
        return <Button type={`link`} onClick={() => this.showDetail(record)}>详情</Button>
      }
    }
  ]

  @action
  showDetail = (record) => {
    return record
  }
}



const UnusualStore = new unusualStore()

export { UnusualStore }
