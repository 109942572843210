import { observable } from 'mobx'
import { GoodsService } from '../../libs/api'
import { message } from 'antd'

class CheckPage {
  @observable list = []
  @observable wBookPageList = []
  @observable bookPageList = []
  @observable refBookPageList = []
  @observable submitLoading = false
  @observable mappingLoading = false
  @observable mappingListLoading = false
  @observable refBookId = ''
  @observable bookId = ''
  @observable loading = false

  /**
   * @description 获取对比中英文列表
   * @returns {Promise<void>}
   */
  getList = async (p = {}) => {
    const params = {}
    for (let key in p) {
      if (p[key]) {
        params[key] = p[key]
      }
    }
    try {
      const res = await GoodsService.getBookPageCompareList(params)
      if (res) {
        const { data } = res
        const { bookId, refBookId, wbookPageList, bookPageList, refBookPageList } = data || {}
        this.bookId = bookId
        this.refBookId = refBookId
        this.wBookPageList = wbookPageList
        this.bookPageList = bookPageList
        this.refBookPageList = refBookPageList
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 获取绘本映射列表
   * @returns {Promise<void>}
   */
  getBookMappingList = async (flag) => {
    let params = {
      bookId: this.bookId,
      pageNum: this.pageNum,
      size: this.size
    }
    if (!flag) {
      this.mappingLoading = true
      try {
        const res = await GoodsService.getBookMappingList(params)
        if (res) {
          const { data, hasMore } = res || {}
          this.hasMore = hasMore
          const { mappingList, book, vtBookList } = data
          this.bookMappingList.mappingList = [...mappingList || []]
          this.bookMappingList.vtBookList = [...vtBookList || []]
          this.bookMappingList.bookInfo = { ...book || {} }
          for (let i = 0; i < this.bookMappingList.vtBookList.length; i++) {
            if (this.bookMappingList.vtBookList[i].mapped) {
              this.defaultChecked.push(this.bookMappingList.vtBookList[i].id)
            }
          }
          this.mappingLoading = false
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        const res = await GoodsService.getBookMappingList(params)
        if (res) {
          const { data, hasMore } = res || {}
          this.hasMore = hasMore
          const { mappingList, book, vtBookList } = data
          this.bookMappingList.mappingList = [...mappingList || []]
          this.bookMappingList.vtBookList = this.bookMappingList.vtBookList.concat(vtBookList || [])
          this.bookMappingList.bookInfo = { ...book || {} }
          for (let i = 0; i < this.bookMappingList.vtBookList.length; i++) {
            if (this.bookMappingList.vtBookList[i].mapped) {
              this.defaultChecked.push(this.bookMappingList.vtBookList[i].id)
            }
          }
          this.mappingListLoading = false
        }
      } catch (e) {
        console.log(e)
      }
    }
  }


  /**
   * @description 更新图片
   * @param params
   */
  updateBookPage = async (params) => {
    try {
      const res = await GoodsService.updateBookPage(params)
      if (res) {
        const { data } = res
        if (data) {
          message.success(`更新成功`)
        } else {
          message.error(`更新失败`)

        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 更新音频信息
   * @param params
   */
  updateBookPageVoice = async (params) => {
    this.loading = true
    try {
      const res = await GoodsService.updateBookPageVoice(params)
      if (res) {
        message.success(`更新成功`)
      }
    } catch (e) {
      console.log(e)
    }
    this.loading = false
  }

  /**
   * @description 新增单页
   * @param params
   * @returns {Promise<void>}
   */
  addLoadPage = async (params = {}) => {
    try {
      const res = await GoodsService.upLoadPage(params)
      if (res) {
        message.success(`更新成功`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 新增音频
   * @param params
   * @returns {Promise<void>}
   */
  addLoadPageVoice = async (params = {}) => {
    try {
      const res = await GoodsService.upLoadPageVoice(params)
      if (res) {
        message.success(`更新成功`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 刷新物理页
   * @returns {Promise<void>}
   */
  refreshPage = async () => {
    try {
      const res = await GoodsService.refreshPage(this.bookId)
      if (res) {
        message.success(`刷新成功`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description  刷新内容页
   */
  refreshPagination = async (params = {}) => {
    try {
      const res = await GoodsService.refreshPagination(params, this.bookId)
      if (res) {
        message.success(` 刷新成功`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 更新loading
   * @param loading
   */
  updateMappingListLoading = (loading) => {
    this.mappingListLoading = loading
  }

  /**
   * @description 更新列表
   * @param list
   */
  updateList = (list = []) => {
    this.list = list
  }

  updateBookList = (item) => {
    this.bookPageList.push(item)
  }

  updateRefBookList = (item) => {
    this.refBookPageList.push(item)
  }
}

const CheckPageStore = new CheckPage()

export { CheckPageStore }
