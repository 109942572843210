import { observable, action, runInAction } from 'mobx'
import { CommonService, GoodsService } from '../../libs/api'

class HomePage {
  @observable list = []
  @observable pageSize = 5
  @observable pageNum = 1
  @observable total = 0
  @observable configList = []

  /**
   * @desc 获取列表
   * @param p
   * @param currentKey
   * @returns {Promise<void>}
   */
  @action
  getList = async (p = {}, currentKey = 'banner') => {
    const params = {
      pageSize: this.pageSize,
      pageNum: this.pageNum
    }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
        // if(k === 'role' && !p[k]){
        //   params.role = []
        // }
      }
    }
    let api
    switch (currentKey) {
      case 'banner':
        api = 'getBmsBannerListV2'
        break
      case 'channel':
        api = 'getBmsChannelList'
        break
      case 'block':
        api = 'getBmsBlockList'
        break
      default:
        api = 'getBmsBannerListV2'
    }
    const res = await GoodsService[api](params)
    const { data, dataCount } = res || {}
    data &&
    data.forEach((it, idx) => {
      it.key = `${idx}-${it.id}`
    })
    runInAction(() => {
      this.list = [...(data || [])]
      this.total = dataCount || 0
    })
  }

  getListV2 = async (p = {}) => {
    const params = {
      pageSize: this.pageSize,
      pageNum: this.pageNum
    }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
        // if(k === 'role' && !p[k]){
        //   params.role = []
        // }
      }
    }
    try {
      const res = await GoodsService.getBmsBannerListV2(params)
      const { data, dataCount } = res || {}
      data &&
      data.forEach((it, idx) => {
        it.key = `${idx}-${it.id}`
      })
      runInAction(() => {
        this.list = [...(data || [])]
        this.total = dataCount || 0
      })

    } catch (e) {
      console.log(e)
    }
  }

  getConfigList = async (name) => {
    const params = {
      size: 50,
      pageNum: 1,
      name
    }

    try {
      const res = await CommonService.getSearchInfo(params)
      if (res) {
        const { data } = res || []
          // this.configList = [...data || []]
          return data
      }
    } catch (e) {
      console.log(e)
    }
  }

  update = async (params = {}, type = 'banner') => {
    let api
    switch (type) {
      case 'banner':
        api = 'editBannerV2'
        break
      case 'channel':
        api = 'editBmsChannel'
        break
      default:
        api = 'editBannerV2'
        break
    }
    try {
      const res = await GoodsService[api](params)
      if (res) {
        const { data } = res
        return data
      }
    } catch (err) {
      console.log(err)
      return null
    }
  }


  /**
   * @desc 切换页数
   * @param pageNum
   */
  @action
  updatePageNum = (pageNum = 1) => {
    this.list = []
    this.pageNum = pageNum
  }

  /**
   * @description 重置页数
   * @param pageNum
   */
  @action
  resetPageNum = (pageNum) => {
    this.pageNum = pageNum
  }

  /**
   * @desc 重置列表数据
   */
  @action
  resetList = () => {
    this.list = []
    this.pageSize = 5
    this.pageNum = 1
    this.total = 0
  }

  /**
   * @description 搜索查询标签
   * @param v
   * @returns {Promise<void>}
   */
  selectAwardUser = async (v) => {
    if (v) {
      try {
        const res = await CommonService.SearchUserByPhone(v)
        if (res) {
          const { data } = res || []
          return data
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  editSearchInfo = async (params = {}) => {
    try {
      const res = await CommonService.editSearchInfo(params)
      if (res) {
        if (res) {
          const { data } = res
          return data
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  clearSystemConfigRedis = async ()=>{
    const res = await CommonService.clearSystemConfigRedis()
    if (res) {
      if (res) {
        const { data } = res
        return data
      }
    }
  }
}


const HomePageStore = new HomePage()

export { HomePageStore }
