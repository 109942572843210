import axios from 'axios'
import Q from 'q'
import { message } from 'antd'
import { createHashHistory } from 'history'
import { env, storage, headerConfig } from './conf'
import { Util } from './util'

axios.defaults.timeout = 100000
axios.defaults.headers.post['Content-Type'] =
	'application/x-www-form-urlencoded'
axios.defaults.baseURL = `${env.DOMAIN}`

// 全局请求拦截
axios.interceptors.request.use(
	(config) => {
		if (config.headers.nodeService) {
			config.baseURL = env.NODE_DOMAIN
		}
		config.headers.platform = headerConfig.platform
		config.headers.appVersion = headerConfig.appVersion
		config.headers.protocolVersion = headerConfig.protocolVersion
		config.headers.deviceId = headerConfig.deviceId
		config.headers.bigSmarter =
			headerConfig.bigSmarter.action.call(headerConfig)

		let tk = Util.getLocalStorage(storage.authInfo) || '{}'
		tk = JSON.parse(tk)
		config.headers.token = tk.token || ''
		return config
	},
	(error) => console.log(error)
)

// 全局响应拦截
axios.interceptors.response.use(
	(response) => {
		const res = response.data || {}
		const status = res.resultStatus || {}
		const { code, message: msg } = status

		if (code === 1000) {
			return res
		} else if (code === 1001) {
			// 登录态失效
			message.error('登录态失效,请重新登录')
			Util.removeLocalStorage(storage.authInfo)
			createHashHistory().replace('/login')
			return null
		} else if (code === 30000) {
			// 无权限访问
			createHashHistory().replace('/noPermission')
			return null
		} else {
			// 接口异常
			message.error(msg || '请求错误')
			// window.vRoute.replace({ path: '/serverExp' });
			return null
		}
	},
	(error) => {
		console.log(error)
		throw error
	}
)

// fetch fn
export default function fetch(
	url,
	{ method = 'GET', params = {}, data = {}, headers = {}, ...options } = {}
) {
	try {
		return Q.Promise((success) => {
			axios({
				data,
				headers,
				method,
				params,
				url,
				...options
			})
				.then((res) => success(res))
				.catch((e) => {
					throw e
				})
		})
	} catch (e) {
		throw e
	}
}
