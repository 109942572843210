import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { HashRouter as Router } from "react-router-dom";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import App from "./components/App";
import stores from "./store";
import "./index.less";

ReactDOM.render(
  <Provider {...stores}>
    <Router basename="/">
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
