import { CommonStore } from './store'
import { CircleStore } from '../views/CircleManagement/store'
import { WeekCourseStore } from '../views/Course/store'
import { PointsOrderStore } from '../views/PointsOrderManagement/store'
import { WhiteListStore } from '../views/WhiteList/store'
import { HomePageStore } from '../views/HomePageManagement/store'
import { AssistantStore } from '../views/ClassCommitteeManagement/store'
import { ClassCommitteeCheckStore } from '../views/ClassCommitteeReview/store'
import { UnusualStore } from '../views/UnusualOrderManagement/store'
import { CheckPageStore } from '../views/CheckBookPage/store'

export default {
  CommonStore,
  CircleStore,
  WeekCourseStore,
  PointsOrderStore,
  WhiteListStore,
  HomePageStore,
  AssistantStore,
  ClassCommitteeCheckStore,
  UnusualStore,
  CheckPageStore
}
