import { observable, action, runInAction } from 'mobx'
import { GoodsService } from '../../libs/api'

class WeekCourse {
  @observable list = []
  @observable size = 30
  @observable pageNum = 1
  @observable total = 0
  @observable detail = {}

  /**
   * @desc 获取列表
   * @param p
   * @returns {Promise<void>}
   */
  @action
  getList = async (p = {}) => {
    const params = {
      size: this.size,
      pageNum: this.pageNum,
    }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
      }
    }
    const res = await GoodsService.getCourseList(params)
    const { data, dataCount } = res || {}
    data &&
      data.forEach((it, idx) => {
        it.key = `${idx}-${it.id}`
      })
    runInAction(() => {
      this.list = [...(data || [])]
      this.total = dataCount || 0
    })
  }

  @action
  getDetail = async (id) => {
    const res = await GoodsService.getCourseInfo(id)
    const detail = (res && res.data) || {}
    runInAction(() => {
      this.detail = { ...detail }
    })
  }

  /**
   * @desc 切换页数
   * @param pageNum
   */
  @action
  updatePageNum = (pageNum = 1) => {
    this.list = []
    this.pageNum = pageNum
  }

  /**
   * @desc 重置列表数据
   */
  @action
  resetList = () => {
    this.list = []
    this.size = 30
    this.pageNum = 1
    this.total = 0
  }

  /**
   * @desc 新增每周一课
   * @memberof WeekCourse
   */
  createWeekCourse = async (params) => {
    const res = await GoodsService.createWeekCourse(params)
    if (res) {
      const { data } = res
      return data
    }
    return false
  }

  /**
   * @desc 更新每周一课
   * @memberof WeekCourse
   */
  updateWeekCourse = async (params) => {
    const res = await GoodsService.updateWeekCourse(params)
    if (res) {
      const { data } = res
      return data
    }
    return false
  }

  /**
   * @edesc 添加课程
   * @memberof WeekCourse
   */
  addAudio = async (params) => {
    const res = await GoodsService.addWeekCourseAudio(params)
    if (res) {
      const { data } = res
      return data
    }
    return false
  }

  /**
   * @edesc 更新课程
   * @memberof WeekCourse
   */
  updateAudio = async (params) => {
    const res = await GoodsService.updateWeekCourseAudio(params)
    if (res) {
      const { data } = res
      return data
    }
    return false
  }
}

const WeekCourseStore = new WeekCourse()

export { WeekCourseStore }
