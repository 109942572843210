import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Bundle from '../components/common/Bundle'
import { Util } from '../libs/util'
import { storage } from '../libs/conf'
import { CommonService } from '../libs/api'

const Login = () => (
  <Bundle load={() => import('../views/Login')}>{(Login) => <Login/>}</Bundle>
)

const Main = () => (
  <Bundle load={() => import('./layout/Main')}>{(Main) => <Main/>}</Bundle>
)

@inject('CommonStore')
@withRouter
@observer
class App extends Component {
  UNSAFE_componentWillMount() {
    this.checkToken()
    this.getAddress()
  }

  componentWillUnmount() {
    this.checkToken = null
  }

  getAddress = async () => {
    const { updateCityOption } = this.props.CommonStore
    try {
      const res = await CommonService.getConfigDetailInfo(4)
      if (res) {
        const { data } = res || {}
        updateCityOption(data.address)
      }
    } catch (err) {
      console.log(err)
    }
  }

  checkToken() {
    let tk = Util.getLocalStorage(storage.authInfo) || '{}'
    tk = JSON.parse(tk)
    if (tk.token) {
      const { updateToken } = this.props.CommonStore
      updateToken(tk.token)
    }
  }

  render() {
    const { token } = this.props.CommonStore
    return token ? <Main/> : <Login/>
  }
}

export default App
