import fetch from '../fetch'

const LogisticsService = {
	/**
	 * @desc 获取物流信息
	 * @param {string} [returnOrderId='']
	 * @returns
	 */
	getShipping(returnOrderId = '') {
		return fetch('/v1/logistics/bms/shipping/shipping/return_order/get_by_id', {
			params: { returnOrderId }
		})
	},

	/**
	 * @description 获取队列信息
	 * @param deviceId
	 * @returns {*}
	 */
	getPackageQueue(deviceId = '') {
		return fetch('/v1/logistics/bms/package/get_package_queue_info', {
			params: { deviceId }
		})
	},

	/**
	 * @description 清除队列
	 * @param deviceId
	 * @returns {*}
	 */
	clearPackageInfo(deviceId = '') {
		return fetch('/v1/logistics/bms/package/clear_package_info', {
			params: { deviceId }
		})
	},

	/**
	 * @description 公司内顺丰寄件
	 * @param params
	 * @returns {*}
	 */
	reserveShunfengOrder(params = {}) {
		return fetch('/v1/logistics/shunfeng/reserve_shunfeng_order', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 公司内顺丰寄件的记录列表
	 * @param params
	 * @returns {*}
	 */
	companyOrderList(params = {}) {
		return fetch('/v1/logistics/shunfeng/company_order_list', {
			method: 'POST',
			data: { ...params }
		})
	}
}

export default LogisticsService
