import { observable, action, runInAction } from 'mobx'
import { CommonService } from '../../libs/api'

class Circle {
  @observable list = []
  @observable size = 30
  @observable pageNum = 1
  @observable total = 0
  @observable eventList = {
    list: [],
    size: 30,
    pageNum: 1,
    total: 0
  }
  @observable organizationList = []

  /**
   * @desc 获取列表
   * @param p
   * @returns {Promise<void>}
   */
  @action
  getList = async (p = {}) => {
    const params = {
      size: this.size,
      pageNum: this.pageNum
    }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
      }
    }
    const res = await CommonService.getCircleList(params)
    const { data, dataCount } = res || {}
    data &&
    data.forEach((it, idx) => {
      it.key = `${idx}-${it.id}`
    })
    runInAction(() => {
      this.list = [...(data || [])]
      this.total = dataCount || 0
    })
  }

  /**
   * @desc 审核
   * @param params
   * @returns {Promise<*>}
   */
  check = async (params) => {
    return await CommonService.commitCircleCheckRes(params)
  }

  /**
   * @desc 切换页数
   * @param pageNum
   */
  @action
  updatePageNum = (pageNum = 1) => {
    this.list = []
    this.pageNum = pageNum
  }

  /**
   * @desc 重置列表数据
   */
  @action
  resetList = () => {
    this.list = []
    this.size = 30
    this.pageNum = 1
    this.total = 0
  }

  /**
   * @desc 获取审核列表
   * @param p
   * @returns {Promise<void>}
   */
  @action
  getEventList = async (p = {}) => {
    const { size, pageNum } = this.eventList
    const params = { size, pageNum }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
      }
    }
    const res = await CommonService.getCircleActivityList(params)
    const { data, dataCount } = res || {}
    data &&
    data.forEach((it, idx) => {
      it.key = `${idx}-${it.id}`
    })
    runInAction(() => {
      this.eventList.list = [...(data || [])]
      this.eventList.total = dataCount || 0
    })
  }

  /**
   * @desc 审核圈子活动
   * @param params
   * @returns {Promise<*>}
   */
  checkEvent = async (params) => {
    return await CommonService.commitCircleTopic(params)
  }

  /**
   * @desc 切换页数
   * @param pageNum
   */
  @action
  updateEventPageNum = (pageNum = 1) => {
    this.eventList.list = []
    this.eventList.pageNum = pageNum
  }

  /**
   * @description 重置活动列表
   */
  @action
  resetEventList = () => {
    this.eventList = {
      list: [],
      size: 30,
      pageNum: 1,
      total: 0
    }
  }

  /**
   * @desc 更新圈子活动
   * @param params
   * @returns {Promise<void>}
   */
  @action
  updateCircleActive = async (params)=>{
    return await CommonService.updateCircleActive(params)

  }

  /**
   * @desc 查询机构
   * @param params
   * @returns {Promise<void>}
   */
  @action
  searchOrganization = async(params)=>{
    const res = await CommonService.searchOrganization(params)
    if(res){
      const {data} = res || {}
      this.organizationList = [...data || []]
    }
  }
}

const CircleStore = new Circle()

export { CircleStore }
