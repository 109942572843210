import { Util } from './util'
import moment from 'moment'

const { location } = window
const { href } = location

const envOptions = {
	// STAGING: 'https://api-test.kangarooread.com',
	// STAGING: 'http://47.98.125.156:8801/',
	STAGING: 'https://api.kangarooread.com/',
	PROD: 'https://api.kangarooread.com',
	CDNTEST: 'https://cdn-test.kangarooread.com/',
	CDNPROD: 'https://cdn.kangarooread.com/',
	NODE_STAGING: 'https://node-test.kangarooread.com/',
	NODE_PROD: 'https://node.kangarooread.com/'
}

const prodReg = /^((http|https):\/\/(bms|bmsll)\.kangarooread\.com)/
const isProd = prodReg.test(href)

// const nodeProdReg = /^((http|https):\/\/(node)\.kangarooread\.com)/
// const isNodeProd = nodeProdReg.test(href)

const serverEnv = isProd ? envOptions.PROD : envOptions.STAGING
const nodeServerEnv = isProd ? envOptions.NODE_PROD : envOptions.NODE_STAGING
const cdnServer = isProd ? envOptions.CDNPROD : envOptions.CDNTEST

const videoDate = moment(new Date()).format('YYYY-MM')
const videoDay = moment(new Date()).date()

const env = {
	DOMAIN: `${serverEnv}`,
	APIVERSION: 'v1',
	NODE_DOMAIN: `${nodeServerEnv}v1`,
	CDN: cdnServer
}

const OSS_UPLOAD_HOST = {
	host: `https://kangaroo-read-cdn${
		isProd ? '' : '-test'
	}.oss-cn-hangzhou.aliyuncs.com/`,
	hostShanghai: 'https://kangaroo-read-page.oss-cn-shanghai.aliyuncs.com/',
	cdn: `https://cdn${isProd ? '' : '-test'}.kangarooread.com/`,
	page: 'https://page.kangarooread.com/'
}

const OSS_DIR = {
	camp: 'resource/bms/camp/',
	qrcode: 'resource/bms/qrcode/',
	weekCourse: 'resource/bms/weekCourse/',
	video: 'resource/bms/video/',
	rich: 'resource/bms/richTextImg/',
	IDcard: 'resource/authentication/',
	manualAudio: 'resource/bms/manualAudio/',
	avatar: 'resource/avatar/',
	banner: 'resource/banner/',
	channel: 'resource/Channel/',
	courseActivity: 'resource/courseActivity/',
	bookVideo: 'resource/audio/book/',
	createWrite: 'resource/createWrite/',
	book: 'resource/book/',
	campSubCourse: 'resource/campSubCourse/',
	checkVideo: `resource/checkVideo/${videoDate}/${videoDay}/`,
	damageFile: 'resource/damageFile/',
	orderCheck: 'resource/orderCheck/',
	entityAudio: 'resource/entityAudio/',
	bookPage: 'resource/bookPage/',
	bookVoice: 'resource/bookVoice/',
	orgImg: 'resource/organization/',
	authorImg: 'resource/author/',
	bookList: 'resource/bookList/',
	rebate: 'resource/rebate/',
	libraryImg: 'library/image/',
	libraryVideo: 'library/video/',
	damageImg: 'resource/damage/',
	notification: 'resource/notification/'
}

const headerConfig = {
	platform: 5, // bms
	appVersion: '1.6.17',
	protocolVersion: 2,
	deviceId: Util.getDeviceId(),
	bigSmarter: {
		action: function () {
			return Util.setMD5(`${this.deviceId}_${this.platform}_${this.appVersion}`)
		}
	}
}

const storage = {
	authInfo: 'auth_info',
	currentMenu: 'current_menu',
	searchForm: 'search_form',
	currentUrl: 'current_url',
	userTag: 'user_tag',
	userTagValue: 'user_tag_value',
	selectKeys: 'select_keys'
}

const CLINT_ROUTER_MAP = [
	{ redirectType: 1, name: '落地页' },
	{
		redirectType: 2,
		name: '网页',
		redirectUrl: '网页地址，例：https://www.baidu.com'
	},
	{ redirectType: 3, name: '在线订阅' },
	{ redirectType: 4, name: '话题详情', redirectUrl: 'id=话题id' },
	{
		redirectType: 5,
		name: '课程详情',
		redirectUrl:
			'id=课程id;source=如果填wechat跳转小程序小鹅通的课、填其他跳Get课'
	},
	{ redirectType: 6, name: '邀请有礼' },
	{ redirectType: 7, name: '积分列表' },
	{ redirectType: 8, name: '话题广场' },
	{ redirectType: 9, name: '每周一课（GET）', redirectUrl: 'id=课程id' },
	{ redirectType: 10, name: '我的书订阅卡' },
	{ redirectType: 11, name: '跳转微信直播间' },
	{ redirectType: 15, name: '伴读打卡' },
	{ redirectType: 16, name: '体验好奇说绘本' },
	{ redirectType: 17, name: '宝宝信息' },
	{ redirectType: 18, name: '专家定制' },
	{ redirectType: 19, name: '绘本详情', redirectUrl: 'id=绘本id' },
	{ redirectType: 20, name: '说说详情', redirectUrl: 'id=帖子id' },
	{ redirectType: 21, name: '主题书单首页' },
	{ redirectType: 22, name: '妈咪宝藏列表页' },
	{ redirectType: 23, name: '妈咪宝藏详情', redirectUrl: 'id=妈咪宝藏id' },
	{ redirectType: 24, name: '福利社列表' },
	{ redirectType: 25, name: 'Get训练营列表' },
	{ redirectType: 26, name: '小小插画家' },
	{ redirectType: 27, name: 'Get课详情', redirectUrl: 'id=话题id' },
	{
		redirectType: 100,
		name: '根据tag搜索的Get课列表',
		redirectUrl: 'tagId=标签id'
	},
	{ redirectType: 101, name: '好奇谷学院列表' },
	{ redirectType: 102, name: '全年课程表' },
	{
		redirectType: 103,
		name: '根据tag搜索课程列表',
		redirectUrl: 'tagId=标签id'
	},
	{
		redirectType: 104,
		name: '订单详情',
		redirectUrl: 'orderType=订单type;orderId=订单Id'
	},
	{ redirectType: 105, name: '首页的发现页' },
	{ redirectType: 106, name: '26800' },
	{ redirectType: 107, name: '2680课程详情', redirectUrl: 'id=课程id' },
	{ redirectType: 108, name: '实名认证' },
	{ redirectType: 109, name: '卡包管理' },
	{ redirectType: 110, name: '推广人奖励页' }
]

const TIME_FORMAT = {
	FULL: 'YYYY-MM-DD HH:mm:ss'
}

const subscribePayWay = [
	{
		name: '支付订阅',
		value: 1
	},
	{
		name: '订阅卡订阅',
		value: 2
	}
]

// 书袋订单状态
const bagOrderStatus = [
	{
		status: -40,
		value: '用户退货'
	},
	{
		status: -30,
		value: '运营退货'
	},
	{
		status: -20,
		value: '物流取消订单'
	},
	{
		status: -10,
		value: '运营取消订单'
	},
	{
		status: -5,
		value: '用户取消订单'
	},
	{
		status: 0,
		value: '待确认'
	},
	{
		status: 5,
		value: '书袋已确认'
	},
	{
		status: 8,
		value: '异常订单'
	},
	{
		status: 10,
		value: '审单中'
	},
	{
		status: 15,
		value: '待发货'
	},
	{
		status: 20,
		value: '已发货'
	},
	{
		status: 30,
		value: '部分签收完成'
	},
	{
		status: 40,
		value: '全部签收完'
	},
	{
		status: 45,
		value: '部分完成'
	},
	{
		status: 50,
		value: '订单完成'
	}
]

// 商品入库状态
const goodsStorageStatus = [
	{
		name: '已入库',
		value: 2
	},
	{
		name: '未入库',
		value: 1
	}
]

// 商品上架状态
const goodsSellStatus = [
	{
		name: '已上架',
		value: 1
	},
	{
		name: '已下架',
		value: 2
	}
]

// 商品库存排序
const goodsStockSort = [
	{ name: '正序', value: 0 },
	{ name: '降序', value: 1 }
]

// 服务状态
const cardServiceStatus = [
	{ name: '未激活', value: 0 },
	{ name: '服务暂停中', value: 2 },
	{ name: '冻结已恢复', value: 5 },
	{ name: '已激活', value: 10 },
	{ name: '服务过期', value: 45 },
	{ name: '押金完成', value: 47 },
	{ name: '服务完成', value: 50 },
	{ name: '已失效', value: 51 },
	{ name: '已失效', value: 52 },
	{ name: '已冻结', value: -5 },
	{ name: '无效', value: -10 },
	{ name: '内测用户退回', value: -66 }
]

// 逾期单状态
const overdueStatus = [
	{ name: '运营取消', value: -10 },
	{ name: '待支付', value: 0 },
	{ name: '部分支付', value: 10 },
	{ name: '已支付', value: 20 },
	{ name: '押金待扣款', value: 25 },
	{ name: '押金已扣款', value: 45 },
	{ name: '已完成', value: 50 }
]

// 追损单状态
const damageStatus = [
	{ name: '客服追损复核驳回', value: -20 },
	{ name: '运营取消', value: -10 },
	{ name: '待支付', value: 0 },
	{ name: '已支付', value: 10 },
	{ name: '已发货', value: 20 },
	{ name: '押金待扣款', value: 25 },
	{ name: '押金已扣款', value: 45 },
	{ name: '已完成', value: 50 }
]

// 报损单状态
const reportStatus = [
	{ name: '报损未处理', value: 0 },
	{ name: '报损已处理', value: 10 }
]

// 入库单状态
const goodsInStatus = [
	{ name: '系统取消', value: -30 },
	{ name: '运营取消', value: -10 },
	{ name: '未验收', value: 0 },
	{ name: '部分验收，待追损', value: 5 },
	{ name: '全部验收，待入库', value: 10 },
	{ name: '部分入库', value: 40 },
	{ name: '入库完成', value: 50 },
	{ name: '上门取件', value: 1 },
	{ name: '用户自寄', value: 2 }
]

// 处理追损单的状态
const processDamageStatus = [
	{ name: '无报损', value: -1 },
	{ name: '追损未处理', value: 0 },
	{ name: '追损已处理', value: 10 }
]

// 寄回的方式
const expressTypeMap = [
	{ name: '上门取件', value: 1 },
	{ name: '用户自寄', value: 2 }
]

// 逆向物流状态
const shippingStatus = [
	{ name: '用户取消', value: -30 },
	{ name: '运营取消', value: -25 },
	{ name: '快递第三方取消', value: -20 },
	{ name: '无法配送', value: -10 },
	{ name: '配送失败', value: -5 },
	{ name: '待确认', value: 0 },
	{ name: '待取件', value: 5 },
	{ name: '已取件', value: 10 },
	{ name: '到达前置仓，待运转', value: 15 },
	{ name: '干线运输中', value: 20 },
	{ name: '待配送', value: 25 },
	{ name: '干线运输中', value: 20 },
	{ name: '配送中', value: 30 },
	{ name: '已签收', value: 35 },
	{ name: '订单关闭', value: 50 }
]

// 回收单列表
const returnStatus = [
	{ name: '物流丢件', value: -30 },
	{ name: '快递取消', value: -23 },
	{ name: '物流取消订单', value: -20 },
	{ name: '运营取消订单', value: -10 },
	{ name: '用户取消订单', value: -5 },
	{ name: '预约失败', value: -2 },
	{ name: '待确认', value: 0 },
	{ name: '待取件', value: 10 },
	{ name: '已发货', value: 20 },
	{ name: '部分签收完', value: 30 },
	{ name: '全部签收完', value: 40 },
	{ name: '部分完成', value: 45 },
	{ name: '订单完成', value: 50 }
]

// 购买单状态
const purchaseStatus = [
	{ name: '已退款', value: -30 },
	{ name: '运营取消订单', value: -10 },
	{ name: '用户取消', value: -5 },
	{ name: '待支付', value: 0 },
	{ name: '部分支付', value: 10 },
	{ name: '已支付', value: 20 },
	{ name: '已完成', value: 50 }
]

// 报损的类型
const damageType = [
	{ name: '缺失', value: 1 },
	{ name: '损坏', value: 2 },
	{ name: '其他', value: 3 },
	{ name: '其他', value: 99 }
]

// 入库subOrder的状态
const goodsInSubOrderStatus = [
	{ name: '无效的入库子订单', value: -30 },
	{ name: '追损复核待确认', value: -20 },
	{ name: '追损复核已确认', value: -15 },
	{ name: '已取消', value: -10 },
	{ name: '残损复核待确认', value: -5 },
	{ name: '残损复核已确认', value: -3 },
	{ name: '待验收', value: 0 },
	{ name: '记录到仓', value: 2 },
	{ name: '待复核', value: 3 },
	{ name: '追损复核驳回', value: 4 },
	{ name: '已验收', value: 5 },
	{ name: '复核入库待确认', value: 8 },
	{ name: '复核入库已确认', value: 9 },
	{ name: '已入库', value: 10 }
]

// 逾期天数排序
const overdueSort = [
	{ name: '正序', value: 1 },
	{ name: '倒叙', value: 2 }
]

// 追损子订单的 状态
const damageSubOrderStatus = [
	{ name: '运营取消订单', value: -10 },
	{ name: '待支付', value: 0 },
	{ name: '已支付', value: 10 },
	{ name: '订单完成', value: 50 }
]

// 代理会员的角色map
const roleMap = [
	{ name: '会员', value: 1 },
	{ name: '推广人', value: 2 },
	{ name: '分会', value: 3 },
	{ name: '服务中心', value: 4 },
	{ name: '机构', value: 5 }
]

// 课程状态
const courseStatus = [
	{ name: '已取消', value: -10, status: 'error' },
	{ name: '未上架', value: 0, status: 'default' },
	{ name: '待开课', value: 5, status: 'warning' },
	{ name: '已开课', value: 10, status: 'processing' }
]

// 复核状态
const reCheckStatus = [
	{ name: '待复核', value: 1 },
	{ name: '已复核，待确认', value: 2 },
	{ name: '追损复核驳回', value: 6 },
	{ name: '已完成', value: 4 }
]

// 复核操作
const reCheckOp = [
	{ name: '追损', value: 1 },
	{ name: '入残损仓', value: 2 },
	{ name: '入库', value: 3 }
]

// 物流公司名称
const expressCompany = [
	{ name: '圆通速递', value: 1 },
	{ name: '申通快递', value: 2 },
	{ name: '中通快递', value: 3 },
	{ name: '韵达速递', value: 4 },
	{ name: '顺丰速运', value: 5 },
	{ name: 'EMS', value: 6 },
	{ name: '京东', value: 7 },
	{ name: '顺丰丰网', value: 8 }
]

// 审单状态
const checkDeliverStatus = [
	{ name: '待审单', value: 5 },
	{ name: '异常订单', value: 8 },
	{ name: '已审单（已审未推仓库）', value: 10 }
]

const weightList = [
	{ name: '3公斤(含)以上', value: true },
	{ name: '3公斤以下', value: false }
]

const pointsOrderCheckDeliverStatus = [
	{ name: '待发货', value: 1 },
	{ name: '已发货', value: 2 },
	{ name: '审单异常', value: 4 }
]

// 自己补贴状态
const shelfReturnPayStatus = [
	{ name: '未补贴', value: 0 },
	{ name: '已补贴', value: 10 }
]

// 积分订单搜索状态
const POINTS_ORDER_SEARCH_STATUS = [
	{ value: 1, label: '待支付' },
	{ value: 2, label: '已支付' },
	{ value: 3, label: '已发货' },
	{ value: 4, label: '已完成' }
]

// 积分订单状态
const POINTS_ORDER_STATUS = [
	{ value: -30, label: '已退款', type: 'default' },
	{ value: -20, label: '系统取消', type: 'default' },
	{ value: -10, label: '用户取消', type: 'default' },
	{ value: 0, label: '待支付', type: 'warning' },
	{ value: 5, label: '积分已支付', type: 'processing' },
	{ value: 10, label: '全部支付', type: 'processing' },
	{ value: 11, label: '发货审单异常', type: 'error' },
	{ value: 12, label: '发货审单', type: 'processing' },
	{ value: 13, label: '待确认发货', type: 'processing' },
	{ value: 15, label: '待发货', type: 'processing' },
	{ value: 20, label: '已发货', type: 'processing' },
	{ value: 30, label: '已收货', type: 'processing' },
	{ value: 50, label: '已完成', type: 'success' }
]

const EXPRESS_COMPANY = [
	{ value: 'POST', label: '中国邮政' },
	{ value: 'EMS', label: 'EMS标准快递' },
	{ value: 'QFKD', label: '全峰' },
	{ value: 'SF', label: '顺丰' },
	{ value: 'YTO', label: '圆通' },
	{ value: 'STO', label: '申通' },
	{ value: 'YUNDA', label: '韵达' },
	{ value: 'HTKY', label: '百世汇通' },
	{ value: 'ZTKY', label: '中铁快运' },
	{ value: 'FAST', label: '快捷速递' },
	{ value: 'TTKDEX', label: '天天快递' },
	{ value: 'ZJS', label: '宅急送' },
	{ value: 'UC', label: '优速' },
	{ value: 'EYB', label: 'EMS经济快递' },
	{ value: 'POSTB', label: '邮政小包' },
	{ value: 'GTO', label: '国通快递' },
	{ value: 'JD', label: '京邦达' },
	{ value: 'ZTO', label: '中通' }
]

const SUBCOURSE_TYPE = [
	{ key: 1, value: 'openBusiness', label: '开营仪式' },
	{ key: 2, value: 'openBusiness', label: '绘本的“自我介绍”' },
	{ key: 3, value: 'openBusiness', label: '绘声绘色的故事妈妈' },
	{ key: 4, value: 'openBusiness', label: '声音塑造' },
	{ key: 5, value: 'openBusiness', label: '解读绘本的三部曲' },
	{ key: 6, value: 'openBusiness', label: '亲子伴读技巧全攻略' },
	{ key: 7, value: 'openBusiness', label: '如何用绘本育人育己' },
	{ key: 8, value: 'openBusiness', label: '如何策划绘本活动' },
	{ key: 9, value: 'openBusiness', label: '实践规则同步' },
	{ key: 10, value: 'openBusiness', label: '小组绘本研读' },
	{ key: 11, value: 'openBusiness', label: '复习' },
	{ key: 12, value: 'openBusiness', label: '模拟考试笔试' },
	{ key: 13, value: 'openBusiness', label: '模拟考试口试' },
	{ key: 14, value: 'openBusiness', label: '正式考试笔试' },
	{ key: 15, value: 'openBusiness', label: '正式考试口试' },
	{ key: 16, value: 'openBusiness', label: '学习总结' },
	{ key: 17, value: 'openBusiness', label: '结营仪式' }
]

const PRICE_COURSE_CAMP = [
	{ value: 90, name: '30天故事妈妈赋能营' },
	{ value: 170, name: '30天研学妈妈赋能营' }
]

const COMMITTEE_ROLE = [
	{ value: 1, name: '实习助教' },
	{ value: 2, name: '助教' },
	{ value: 3, name: '实习班主任' },
	{ value: 4, name: '班主任' }
]

const SOURCE_LIST = [
	{ value: 1, label: ' 退卡/退激活' },
	{ value: 7, label: '故事妈妈退款' },
	{ value: 8, label: '分会长退款' },
	{ value: 10, label: '研学妈妈退款' },
	{ value: 11, label: '故事会退款' },
	{ value: 14, label: '故宫课退款' }
]

const COUPON_SOURCE = [
	{ value: 1, label: '首页优惠券弹窗列表' },
	{ value: 2, label: '后台发放' },
	{ value: 3, label: '积分商城兑换' },
	{ value: 6, label: '他人转赠' },
	{ value: 7, label: '活动领取' },
	{ value: 101, label: '2019年双十一活动' }
]

const COUPON_USED_STATUS = [
	{ value: -5, label: '已过期', status: 'default' },
	{ value: -1, label: '已转赠', status: 'default' },
	{ value: 0, label: '已使用', status: 'success' },
	{ value: 8, label: '未激活', status: 'processing' },
	{ value: 10, label: '未使用', status: 'processing' }
]

const STAFF_ROLE = [
	{ value: 1, label: '超级管理员' },
	{ value: 2, label: '仓库管理员' },
	{ value: 3, label: '客服' },
	{ value: 4, label: '仓库质检员' },
	{ value: 5, label: '运营' },
	{ value: 6, label: '管理员' },
	{ value: 8, label: '口试审核人员' }
]

//购买单自订单状态
const PURCHASE_SUB_STATUS = [
	{ value: -30, label: '已退款' },
	{ value: -10, label: '运行取消订单' },
	{ value: -5, label: '用户取消订单' },
	{ value: 0, label: '待支付' },
	{ value: 10, label: '已支付' },
	{ value: 20, label: '全部付款' },
	{ value: 50, label: '订单完成' }
]

//绘本对比单页状态
const PAGE_STATUS = [
	{ value: 1, label: '封面' },
	{ value: 2, label: '封里' },
	{ value: 3, label: '扉页' },
	{ value: 4, label: '目录' },
	{ value: 5, label: '正文' },
	{ value: 6, label: '辅文' },
	{ value: 7, label: '封底里' },
	{ value: 8, label: '封底' },
	{ value: -1, label: '其它' }
]

const LEAD_LEVEL = [
	{ value: -1, label: '实习领读者' },
	{ value: 1, label: '领读者' },
	{ value: 2, label: '领读者' },
	{ value: 3, label: '领读者' },
	{ value: 4, label: '资深领读者' },
	{ value: 5, label: '资深领读者' },
	{ value: 6, label: '领读者导师' }
]

const SERVICE_TYPE = [
	{ value: 1, label: '绘本订阅会员' },
	{ value: 11, label: '好奇宝宝会员' },
	{ value: 10, label: '好奇宝宝会员（升级）' },
	{ value: 12, label: '正价课会员' }
]

const BIND_TYPE = [
	{ value: 0, label: '小程序注册绑定' },
	{ value: 1, label: 'app注册绑定' },
	{ value: 2, label: '加库存未激活' },
	{ value: 3, label: '激活' },
	{ value: 4, label: '兑换码兑换绑定' },
	{ value: 5, label: '引流付费' },
	{ value: 6, label: '正价课' },
	{ value: 7, label: '长期绑定' },
	{ value: 8, label: '过期' },
	{ value: 9, label: '预签约' },
	{ value: 10, label: '签约' },
	{ value: 11, label: '签约扣费' }
]

const BIG_GIFT_STATUS = [
	{ value: -99, label: '无记录' },
	{ value: -30, label: '退款' },
	{ value: -20, label: '系统取消' },
	{ value: -10, label: '用户取消' },
	{ value: 0, label: '待支付' },
	{ value: 5, label: '积分已支付' },
	{ value: 10, label: '全部支付' },
	{ value: 11, label: '发货审单异常' },
	{ value: 12, label: '发货审单' },
	{ value: 13, label: '待确认发货' },
	{ value: 15, label: '待发货' },
	{ value: 20, label: '已发货' },
	{ value: 30, label: '已收获' },
	{ value: 50, label: ' 已完成' }
]

const GDD_STATUS = [
	{ value: -10, label: '无效' },
	{ value: 5, label: '待激活' },
	{ value: 10, label: '已激活' },
	{ value: 15, label: '待归还' },
	{ value: 20, label: '已过期' },
	{ value: 45, label: '挂失' },
	{ value: 50, label: '已归还' }
]

const REFER_STATUS = [
	{ value: 0, label: '小程序注册绑定' },
	{ value: 1, label: 'APP注册绑定' },
	{ value: 2, label: '加库存未激活' },
	{ value: 3, label: '激活' },
	{ value: 4, label: '兑换码兑换绑定' },
	{ value: 5, label: '引流付费' },
	{ value: 6, label: '正价课程' },
	{ value: 7, label: '长期绑定' },
	{ value: 8, label: '过期' },
	{ value: 9, label: '预签约' },
	{ value: 10, label: '签约' },
	{ value: 11, label: '签约扣费' },
	{ value: 12, label: '关系变更' }
]

const TAG_STATUS = [
	{ value: 8, label: '绘本标签一级' },
	{ value: 9, label: '绘本标签二级' },
	{ value: 12, label: '大奖标签' },
	{ value: 13, label: '系列标签' }
]

const BUSINESS_TYPE = [
	{ value: 6, label: '课程成本' },
	{ value: 7, label: '助教奖励' },
	{ value: 8, label: '运营奖励' },
	{ value: 12, label: '培训费' },
	{ value: 13, label: '城市运营官薪资' }
]

const COUNTRY_TYPE = [
	{ value: '奥', label: '奥' },
	{ value: '澳', label: '澳' },
	{ value: '比', label: '比' },
	{ value: '波', label: '波' },
	{ value: '丹', label: '丹' },
	{ value: '德', label: '德' },
	{ value: '俄', label: '俄' },
	{ value: '法', label: '法' },
	{ value: '韩', label: '韩' },
	{ value: '荷', label: '荷' },
	{ value: '加', label: '加' },
	{ value: '捷', label: '捷' },
	{ value: '美', label: '美' },
	{ value: '葡', label: '葡' },
	{ value: '日', label: '日' },
	{ value: '西', label: '西' },
	{ value: '意', label: '意' },
	{ value: '英', label: '英' },
	{ value: '中', label: '中' },
	{ value: '巴西', label: '巴西' },
	{ value: '阿根廷', label: '阿根廷' },
	{ value: '爱尔兰', label: '爱尔兰' },
	{ value: '哥伦比亚', label: '哥伦比亚' },
	{ value: '克罗地亚', label: '克罗地亚' },
	{ value: '肯尼亚', label: '肯尼亚' },
	{ value: '立陶宛', label: '立陶宛' },
	{ value: '马达加斯加', label: '马达加斯加' },
	{ value: '马拉维', label: '马拉维' },
	{ value: '墨西哥', label: '墨西哥' },
	{ value: '南非', label: '南非' },
	{ value: '挪威', label: '挪威' },
	{ value: '新西兰', label: '新西兰' },
	{ value: '匈牙利', label: '匈牙利' },
	{ value: '伊朗', label: '伊朗' },
	{ value: '瑞典', label: '瑞典' },
	{ value: '瑞士', label: '瑞士' },
	{ value: '乌拉圭', label: '乌拉圭' },
	{ value: '斯洛文尼亚', label: '斯洛文尼亚' }
]

const ORDER_STATUS = [
	{ value: -30, label: '已退款' },
	{ value: -20, label: '用户取消' },
	{ value: -10, label: '运营取消' },
	{ value: 0, label: '待支付' },
	{ value: 10, label: '已支付' },
	{ value: 19, label: '部分发货' },
	{ value: 20, label: '已发货' },
	{ value: 50, label: '已完成' }
]

export {
	isProd,
	env,
	storage,
	headerConfig,
	TIME_FORMAT,
	subscribePayWay,
	bagOrderStatus,
	goodsStorageStatus,
	goodsSellStatus,
	goodsStockSort,
	cardServiceStatus,
	overdueStatus,
	damageStatus,
	reportStatus,
	goodsInStatus,
	processDamageStatus,
	expressTypeMap,
	shippingStatus,
	returnStatus,
	purchaseStatus,
	damageType,
	goodsInSubOrderStatus,
	overdueSort,
	damageSubOrderStatus,
	roleMap,
	courseStatus,
	reCheckStatus,
	reCheckOp,
	expressCompany,
	checkDeliverStatus,
	shelfReturnPayStatus,
	OSS_UPLOAD_HOST,
	OSS_DIR,
	POINTS_ORDER_SEARCH_STATUS,
	POINTS_ORDER_STATUS,
	EXPRESS_COMPANY,
	CLINT_ROUTER_MAP,
	SUBCOURSE_TYPE,
	PRICE_COURSE_CAMP,
	COMMITTEE_ROLE,
	SOURCE_LIST,
	pointsOrderCheckDeliverStatus,
	COUPON_SOURCE,
	COUPON_USED_STATUS,
	STAFF_ROLE,
	weightList,
	PURCHASE_SUB_STATUS,
	PAGE_STATUS,
	LEAD_LEVEL,
	SERVICE_TYPE,
	BIND_TYPE,
	GDD_STATUS,
	BIG_GIFT_STATUS,
	REFER_STATUS,
	TAG_STATUS,
	BUSINESS_TYPE,
	COUNTRY_TYPE,
	ORDER_STATUS
}
