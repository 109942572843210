import accounting from 'accounting'
import md5 from 'js-md5'
import { createHashHistory } from 'history'
import { CommonStore } from '../store/store'

const HISTORY = createHashHistory()

const formatNumber = (n) => {
  const s = n.toString()
  return s[1] ? s : `0${s}`
}

const base64EncodeChars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

/* eslint-disable */
function typeOf(obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

// deepCopy
function deepCopy(data) {
  const t = typeOf(data)
  let o

  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return data
  }

  if (t === 'array') {
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]))
    }
  } else if (t === 'object') {
    for (let i in data) {
      o[i] = deepCopy(data[i])
    }
  }
  return o
}

/* eslint-enable */

// 节流
function throttle(fn, interval = 200) {
  let last
  const time = interval
  let timer = null
  return function (...args) {
    // eslint-disable-line
    const th = this
    const now = +new Date()
    if (last && now - last < time) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(th, args)
      }, time)
    } else {
      last = now
      fn.apply(th, args)
    }
  }
}

const Util = {
  json2str(obj) {
    return JSON.stringify(obj, function (key, val) {
      if (typeof val === 'function') {
        val = val.toString()
      }
      return val
    })
  },

  base64encode(str) {
    let out = ''
    let i = 0
    let c1, c2, c3
    const len = str.length
    while (i < len) {
      c1 = str.charCodeAt(i++) & 0xff
      if (i === len) {
        out += base64EncodeChars.charAt(c1 >> 2)
        out += base64EncodeChars.charAt((c1 & 0x3) << 4)
        out += '=='
        break
      }
      c2 = str.charCodeAt(i++)
      if (i === len) {
        out += base64EncodeChars.charAt(c1 >> 2)
        out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
        out += base64EncodeChars.charAt((c2 & 0xf) << 2)
        out += '='
        break
      }
      c3 = str.charCodeAt(i++)
      out += base64EncodeChars.charAt(c1 >> 2)
      out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
      out += base64EncodeChars.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6))
      out += base64EncodeChars.charAt(c3 & 0x3f)
    }
    return out
  },

  getQueryString(url, name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = url.slice(url.search(/\?/)).substr(1).match(reg)
    if (r != null) {
      return unescape(r[2])
    }
    return null
  },

  getDeviceId() {
    let agent = navigator.userAgent
    agent = agent.substr(2, 12)
    return `${agent}${(Math.random() * 10000).toFixed(0)}`
  },
  /**
   * md5加密
   */
  setMD5(str) {
    return md5(str)
  },

  /**
   * 格式化金额
   */
  formatMoney: (m, prev = '', precision = 2, symbol = '') =>
    accounting.formatMoney(m, prev, precision, ',', '.', { symbol }),

  /**
   * 分 => 元
   */
  fenToYuan(m, prev = '', symbol = '', precision = 2) {
    const _precision = (m || 0) % 100 === 0 ? 0 : precision
    const o = m ? this.numberCal.div(m, 100) : 0
    return o === 0
      ? `${prev}0${symbol}`
      : accounting.formatMoney(o, prev, _precision, '', '.', { symbol }) * 1
  },

  /**
   * 格式化日期
   */
  formatTime: (date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return `${[year, month, day].map(formatNumber).join('-')} ${[
      hour,
      minute,
      second
    ]
    .map(formatNumber)
    .join(':')}`
  },

  /**
   * 浮点数计算； 规避浮点数的精度丢失；
   * eg: 32.80*100 // 3279.9999999999995;
   */
  numberCal: {
    add(a, b) {
      let c
      let d
      try {
        c = a.toString().split('.')[1].length
      } catch (f) {
        c = 0
      } // eslint-disable-line
      try {
        d = b.toString().split('.')[1].length
      } catch (f) {
        d = 0
      } // eslint-disable-line
      const e = 10 ** Math.max(c, d)
      return (this.mul(a, e) + this.mul(b, e)) / e
    },
    sub(a, b) {
      let c
      let d
      try {
        c = a.toString().split('.')[1].length
      } catch (f) {
        c = 0
      } // eslint-disable-line
      try {
        d = b.toString().split('.')[1].length
      } catch (f) {
        d = 0
      } // eslint-disable-line
      const e = 10 ** Math.max(c, d)
      return (this.mul(a, e) - this.mul(b, e)) / e
    },
    mul(a, b) {
      let c = 0
      const d = a.toString()
      const e = b.toString()
      try {
        c += d.split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      try {
        c += e.split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      return (Number(d.replace('.', '')) * Number(e.replace('.', ''))) / 10 ** c
    },
    div(a, b) {
      let e = 0
      let f = 0
      try {
        e = a.toString().split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      try {
        f = b.toString().split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      const c = Number(a.toString().replace('.', ''))
      const d = Number(b.toString().replace('.', ''))
      return this.mul(c / d, 10 ** (f - e))
    }
  },

  /**
   @description localStorage, sessionStorage 删除、更新、查看;
   @param {String} key
   @param {String} value
   * */
  getLocalStorage(k) {
    return window.localStorage.getItem(k) || ''
  },
  getSessionStorage(k) {
    return window.sessionStorage.getItem(k) || ''
  },

  setLocalStorage(k, value) {
    window.localStorage.setItem(k, value)
  },
  setSessionStorage(k, value) {
    window.sessionStorage.setItem(k, value)
  },

  removeLocalStorage(k) {
    window.localStorage.removeItem(k)
  },
  removeSessionStorage(k) {
    window.sessionStorage.removeItem(k)
  },

  // 打开新窗口
  newWindow(url) {
    if (!url) return
    const { location } = window
    const { origin } = location
    window.open(`${origin}#${url}`)
  },

  // 路由 => push
  pushRouter(url) {
    if (!url) return
    HISTORY.push(url)
  },

  // 路由 => replace
  replaceRouter(url) {
    if (!url) return
    HISTORY.replace(url)
  },

  // 截取url上面的字段
  getParams(str = '') {
    if (!str) return {}
    try {
      let paramsStr = str.split('?')[1]
      let list = paramsStr.split('&')
      let params = {}
      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          const key = list[i].split('=')[0]
          const value = list[i].split('=')[1]
          params[key] = value
        }
        return params
      }
      return {}
    } catch (err) {
      console.log(err)
    }
  },

  /**
   * @desc 转半角
   * @param {*} str
   * @returns
   */
  toDBC(str) {
    let result = ''
    const len = str.length
    for (let i = 0; i < len; i++) {
      let cCode = str.charCodeAt(i)
      //全角与半角相差（除空格外）：65248（十进制）
      cCode = cCode >= 0xff01 && cCode <= 0xff5e ? cCode - 65248 : cCode
      //处理空格
      cCode = cCode === 0x03000 ? 0x0020 : cCode
      result += String.fromCharCode(cCode)
    }
    return result
  },

  /**
   * @desc 按钮权限判断
   * @param {string} [key='']
   * @returns
   */
  checkBtnAuth(key = '') {
    if (!key) {
      return false
    }
    let list = []
    const { btnAuthList } = CommonStore
    if (btnAuthList && btnAuthList.length > 0) {
      list = btnAuthList
    } else {
      let authInfo = window.localStorage.getItem('auth_info') || ''
      authInfo = JSON.parse(authInfo)
      const { function: btnAuth } = authInfo
      if (btnAuth) {
        list = btnAuth.split(',')
      }
    }
    if (list.length < 1) {
      return false
    }
    return list.includes(key)
  },

  /**
   * @desc 设置回显图片数据
   * @param {*}  pic
   * @returns
   */
  setImg(pic) {
    if (pic.length === 0) {
      return []
    }
    let imgArr = []
    if (Array.isArray(pic)) {
      if (pic.length) {
        imgArr = imgArr.concat(
          pic.map((el, idx) => {
            return {
              uid: idx,
              name: `file_${idx}`,
              url: el,
              status: 'done'
            }
          })
        )
      }
    }
    return imgArr
  },

  /**
   * @desc 处理多媒体时间
   * @param {number} [time=0]
   * @returns
   */
  processMediaTime(time = 0) {
    if (time === 0) {
      return '0分0秒'
    }
    const min = `${Math.floor(time / 60)}分`
    const sed = `${time % 60}秒`
    return min + sed
  },

  /**
   * @description 更新标签页
   * @param router
   * @param title
   */
  updateTab(router = '', title = '') {
    const { updateTabList, updateTabValue, tabList } = CommonStore
    for (let i = 0; i < tabList.length; i++) {
      if (tabList[i].router === router) {
        updateTabValue(router)
        this.pushRouter(router)
        return
      }
    }
    updateTabList({
      router: router,
      title: title,
      showClose: true,
      key: router
    })
    updateTabValue(router)
  },


  updateUserTab(router = '', title = '', close = true) {
    const { updateUserTabList, updateUserTabValue, userTabList } = CommonStore
    for (let i = 0; i < userTabList.length; i++) {
      if (userTabList[i].router === router) {
        updateUserTabValue(router)
        this.pushRouter(router)
        return
      }
    }
    updateUserTabList({
      router: router,
      title: title,
      showClose: close,
      key: router
    })
    updateUserTabValue(router)
  },

  getLevel(l) {
    if (l > 0 && l < 4) {
      return 'normal'
    } else if (l >= 4 && l <= 5) {
      return 'senior'
    } else if (l === 6) {
      return 'tutor'
    } else {
      return 'other'
    }
  }
}

const Validate = {
  isMobile: (v) => {
    if (!v) return false
    return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(v)
  },
  isPhone: () => {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  }
}

export { Util, Validate, deepCopy, throttle }
