import { observable, action, runInAction } from 'mobx'
import { GoodsService } from '../../libs/api'
import { message } from 'antd'

class ClassCommitteeCheck {
  @observable applyCheckListInfo = {
    list: [],
    size: 10,
    pageNum: 1,
    total: 0
  }
  @observable checkPassedListInfo = {
    list: [],
    size: 10,
    pageNum: 1,
    total: 0
  }
  @observable assistantDetail = {}

  /**
   * @desc 获取列表
   * @param p Object 参数
   * @param type String apply: 申请类表 passed：全部通过列表
   * @returns {Promise<void>}
   */
  getList = async (p = {}, type = 'apply') => {
    let api, params
    switch (type) {
      case 'apply':
        api = 'queryNeedCheckList'
        params = {
          size: this.applyCheckListInfo.size,
          pageNum: this.applyCheckListInfo.pageNum
        }
        break
      case 'passed':
        api = 'queryAllPassList'
        params = {
          size: this.checkPassedListInfo.size,
          pageNum: this.checkPassedListInfo.pageNum
        }
        break
      default:
        break
    }
    if (!api) {
      message.warning('错误的请求')
      return
    }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
      }
    }
    try {
      const res = await GoodsService[api](params)
      const { data, dataCount } = res || {}
      data &&
      data.forEach((it, idx) => {
        it.key = `${idx}-${it.id}`
      })
      runInAction(() => {
        if (type === 'apply') {
          this.applyCheckListInfo.list = [...(data || [])]
          this.applyCheckListInfo.total = dataCount || 0
        }
        if (type === 'passed') {
          this.checkPassedListInfo.list = [...(data || [])]
          this.checkPassedListInfo.total = dataCount || 0
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 切换页数
   * @param pageNum
   * @param type 类型
   */
  @action
  updatePageNum = (pageNum = 1, type = 'apply') => {
    if (type === 'apply') {
      this.applyCheckListInfo.list = []
      this.applyCheckListInfo.pageNum = pageNum
    }
    if (type === 'passed') {
      this.checkPassedListInfo.list = []
      this.checkPassedListInfo.pageNum = pageNum
    }
  }

  /**
   * @desc 重置列表数据
   * @param type
   */
  @action
  resetList = (type = 'apply') => {
    if (type === 'apply') {
      this.applyCheckListInfo.list = []
      this.applyCheckListInfo.size = 10
      this.applyCheckListInfo.pageNum = 1
      this.applyCheckListInfo.total = 0
    }
    if (type === 'passed') {
      this.checkPassedListInfo.list = []
      this.checkPassedListInfo.size = 10
      this.checkPassedListInfo.pageNum = 1
      this.checkPassedListInfo.total = 0
    }
  }

  /**
   * @description 查询班委详情
   * @param id
   * @returns {Promise<void>}
   */
  @action
  queryAssistantDetail = async (id) => {
    try {
      const res = await GoodsService.queryAssistantDetail(id)
      if (res) {
        const { data } = res
        runInAction(() => {
          this.assistantDetail = { ...(data || {}) }
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 清除本地班委缓存
   */
  @action
  clearAssistantDetail = () => {
    this.assistantDetail = {}
  }

  /**
   * @description 审核带班申请
   * @param params
   * @returns {Promise<null|*>}
   */
  checkAssistant = async (params = {}) => {
    try {
      const res = await GoodsService.checkAssistant(params)
      if (res) {
        const { data } = res
        return data
      }
    } catch (err) {
      console.log(err)
    }
    return null
  }
}

const ClassCommitteeCheckStore = new ClassCommitteeCheck()

export { ClassCommitteeCheckStore }
