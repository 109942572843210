import { observable, action, runInAction } from 'mobx'
import { OrderService } from '../../libs/api'

class PointsOrder {
  @observable loading = false
  @observable list = []
  @observable size = 10
  @observable pageNum = 1
  @observable total = 0
  @observable detail = {}

  /**
   * @desc 获取列表
   * @param p
   * @returns {Promise<void>}
   */
  @action
  getList = async (p = {}) => {
    this.showLoading()
    const params = {
      pageSize: this.size,
      pageNum: this.pageNum
    }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
      }
    }
    const res = await OrderService.getPointsOrderList(params)
    const { data, dataCount } = res || {}
    data &&
    data.forEach((it, idx) => {
      it.key = `${idx}-${it.id}`
    })
    runInAction(() => {
      this.list = [...(data || [])]
      this.total = dataCount || 0
      this.hideLoading()
    })
  }

  getDetail = async (id) => {
    try {
      const res = await OrderService.getPointOrderDetail(id)
      if (res) {
        const { data } = res || {}
        this.detail = data
      }
    } catch (e) {
      console.log(e)
    }
  }
  /**
   * @desc 切换页数
   * @param pageNum
   */
  @action
  updatePageNum = (pageNum = 1) => {
    this.list = []
    this.pageNum = pageNum
  }

  /**
   * @desc 重置列表数据
   */
  @action
  resetList = () => {
    this.list = []
    this.size = 10
    this.pageNum = 1
    this.total = 0
  }

  @action
  showLoading = () => {
    this.loading = true
  }

  @action
  hideLoading = () => {
    this.loading = false
  }
}

const PointsOrderStore = new PointsOrder()

export { PointsOrderStore }
