import fetch from '../fetch'

const NodeService = {
	/**
	 * @description 查看日志
	 * @param params
	 * @returns {*}
	 */
	getLogList(params = {}) {
		return fetch(`/node/bms/log/list`, {
			params: { ...params },
			headers: {
				nodeService: true
			}
		})
	},

	/**
	 * @description 获取视频时长
	 * @param url
	 * @returns {*}
	 */
	getStockVideoDuration(url) {
		return fetch('/node/bms/tools/get_video_duration', {
			params: { url },
			headers: {
				nodeService: true
			}
		})
	}
}

export default NodeService
