import fetch from '../fetch'

const DistributionService = {
	/**
	 * @description 获取提现审核列表
	 * @param params
	 * @returns {*}
	 */
	importReadExcel(params = {}) {
		return fetch('/v2/distribution/bms/asset_release/read_excel', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取提现审核列表
	 * @param params
	 * @returns {*}
	 */
	getWithdrawApplyList(params = {}) {
		return fetch('/v2/distribution/bms/withdraw/withdraw_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 提现通过审核
	 * @param params
	 * @returns {*}
	 */
	processWithdrawApply(params = {}) {
		return fetch('/v2/distribution/bms/withdraw/process_apply', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 提现驳回
	 * @param params
	 * @returns {*}
	 */
	rejectWithdrawApply(params = {}) {
		return fetch('/v2/distribution/bms/withdraw/reject_apply', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取用户提现信息
	 * @param userId
	 * @returns {*}
	 */
	getUserBankInfo(userId = '') {
		return fetch(`/v2/distribution/bms/withdraw/bank_info/${userId}`)
	},

	/**
	 * @description 更新用户提现银行相关信息
	 * @param params
	 * @returns {*}
	 */
	updateBankInfo(params = {}) {
		return fetch('/v2/distribution/bms/withdraw/update_bank_info', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取服务质量分
	 * @returns {*}
	 */
	getServicePointList(userId = '') {
		return fetch(`/v2/distribution/bms/reader/kpi/service_score_list`, {
			params: { userId }
		})
	},

	/**
	 * @description 面试是否通过
	 * @returns {*}
	 */
	getInterviewPassInfo(params = {}) {
		return fetch(`/v2/distribution/bms/reader/kpi/interview_pass`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取服务质量分详情
	 * @param params
	 * @returns {*}
	 */
	getServicePointRecord(params = {}) {
		return fetch(`/v2/distribution/bms/reader/kpi/service_score_record_list`, {
			params: { ...params }
		})
	},

	/**
	 * @description 操作服务质量分
	 * @param params
	 * @returns {*}
	 */
	editServiceScore(params) {
		return fetch(`/v2/distribution/bms/reader/kpi/operation_service_score`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 资产列表获取
	 * @param params
	 * @returns {*}
	 */
	getQueryBatch(params = {}) {
		return fetch(`/v2/distribution/bms/asset_release/query_batch`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 资产详情
	 * @param batchId
	 * @returns {*}
	 */
	getQueryBatchDetail(batchId = '') {
		return fetch(
			`/v2/distribution/bms/asset_release/query_batch_detail/${batchId}`
		)
	},

	/**
	 * @description 批量发放资产
	 * @param params
	 * @returns {*}
	 */
	batchRelease(params = {}) {
		return fetch(`/v2/distribution/bms/asset_release/batch_release`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取外部群
	 * @param params
	 * @returns {*}
	 */
	getMemberGroupList(params = {}) {
		return fetch(
			`/v2/distribution/bms/subscribe_member/group/member_group_list`,
			{
				params: { ...params }
			}
		)
	},

	/**
	 * @description 获取外部群成员
	 * @param params
	 * @returns {*}
	 */
	getMemberList(params = {}) {
		return fetch(`/v2/distribution/bms/subscribe_member/group/member_list`, {
			params: { ...params }
		})
	},

	/**
	 * @description 更新群成员身份
	 * @param params
	 * @returns {*}
	 */
	updateMemberRole(params) {
		return fetch(
			'/v2/distribution/bms/subscribe_member/group/update_member_role',
			{
				method: 'POST',
				data: { ...params }
			}
		)
	}
}

export default DistributionService
