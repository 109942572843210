import { observable, action, runInAction } from 'mobx'
import { GoodsService } from '../../libs/api'
import { message } from 'antd'

class Assistant {
  @observable list = []
  @observable size = 30
  @observable pageNum = 1
  @observable total = 0
  @observable campList = []
  @observable currentCourseType = ''
  @observable campInfo = {}
  @observable campEnlistCompleteList = []
  @observable assistantDetail = {}
  @observable campTeacherList = []
  @observable campAssistantListRole4 = []
  @observable campAssistantListRole3 = []

  /**
   * @desc 获取列表
   * @param p
   * @returns {Promise<void>}
   */
  getList = async (p = {}) => {
    const params = {
      size: this.size,
      pageNum: this.pageNum
    }
    for (let k in p) {
      if (p.hasOwnProperty(k)) {
        params[k] = p[k]
      }
    }
    try {
      const res = await GoodsService.getCampEnlistList(params)
      const { data, dataCount } = res || {}
      data &&
      data.forEach((it, idx) => {
        it.key = `${idx}-${it.id}`
      })
      runInAction(() => {
        this.list = [...(data || [])]
        this.total = dataCount || 0
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 新建助教
   * @param params
   * @returns {Promise<void>}
   */
  @action
  addCampAssistantEnlist = async (params) => {
    const res = await GoodsService.addCampAssistantEnlist(params)
    try {
      if (res) {
        message.success('创建成功')
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 获取对应招募信息
   * @param params
   * @returns {Promise<void>}
   */
  getCampList = async (params = {}) => {
    const { courseType } = params
    if (this.currentCourseType === courseType) return
    this.currentCourseType = courseType
    try {
      const res = await GoodsService.get2680CampList(params)
      if (res) {
        const { data } = res || {}
        this.campList = data || []
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 切换页数
   * @param pageNum
   */
  @action
  updatePageNum = (pageNum = 1) => {
    this.list = []
    this.pageNum = pageNum
  }

  /**
   * @desc 重置列表数据
   */
  @action
  resetList = () => {
    this.list = []
    this.size = 30
    this.pageNum = 1
    this.total = 0
  }

  /**
   * @description 新建招募
   * @param params
   * @returns {Promise<*>}
   */
  addEnlist = async (params) => {
    let result
    try {
      const res = await GoodsService.addCampAssistantEnlist(params)
      if (res) {
        const { data } = res
        result = data
      }
    } catch (err) {
      console.log(err)
    }
    return result
  }

  /**
   * @description 更新招募
   * @param params
   * @returns {Promise<*>}
   */
  updateEnlist = async (params) => {
    let result
    try {
      const res = await GoodsService.addCampAssistantEnlist(params)
      if (res) {
        const { data } = res
        result = data
      }
    } catch (err) {
      console.log(err)
    }
    return result
  }

  /**
   * @description 获取camp招募信息
   * @param id
   * @returns {Promise<void>}
   */
  getCampEnlistInfo = async (id) => {
    try {
      const res = await GoodsService.getGetCampEnlistInfo(id)
      if (res) {
        const { data } = res
        this.campInfo = data || {}
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取招募完成列表
   * @param id
   * @returns {Promise<void>}
   */
  getCampEnlistCompleteList = async (id) => {
    try {
      const res = await GoodsService.getOverCampApplyList(id)
      if (res) {
        const { data } = res
        this.campEnlistCompleteList = data || []
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取班委招募中的列表
   * @param params
   * @returns {Promise<void>}
   */
  getCampApplyList = async (params = {}) => {
    try {
      const res = await GoodsService.getCampApplyList(params)
      if (res) {
        const { data } = res
        this.campEnlistApplyList = data || []
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 审核班委申请
   * @param params
   * @returns {Promise<void>}
   */
  checkAssistantApply = async (params = {}) => {
    try {
      const res = await GoodsService.checkAssistantApply(params)
      if (res) {
        const { data } = res
        return data
      }
      return null
    } catch (err) {
      console.log(err)
    }
    return null
  }

  /**
   * @description 查询班委详情
   * @param id
   * @returns {Promise<void>}
   */
  @action
  queryAssistantDetail = async (id) => {
    try {
      const res = await GoodsService.queryAssistantDetail(id)
      if (res) {
        const { data } = res
        runInAction(() => {
          this.assistantDetail = { ...(data || {}) }
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 查询全部班主任
   * @param id
   * @returns {Promise<void>}
   */
  queryCampTeacher = async (id) => {
    try {
      const res = await GoodsService.queryCampTeacher(id)
      if (res) {
        const { data } = res
        runInAction(() => {
          this.campTeacherList = [...(data || [])]
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 查询可用身份
   * @param campId
   * @param role
   * @returns {Promise<void>}
   */
  queryCampAssistantSelect = async (campId, role) => {
    try {
      const res = await GoodsService.queryCampAssistantSelect({ campId, role })
      if (res) {
        const { data } = res
        runInAction(() => {
          this[`campAssistantListRole${role}`] = [...(data || [])]
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

const AssistantStore = new Assistant()

export { AssistantStore }
