import fetch from '../fetch'

const RoleService = {
	/**
	 * @desc 获取角色权限列表
	 * @param {*} [data={}]
	 * @returns
	 */
	getRoleList(data = {}) {
		return fetch('/v1/user/staff_role/list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 更新角色权限
	 * @param {*} [data={}]
	 * @returns
	 */
	updateRole(data = {}) {
		return fetch('/v1/user/staff_role/update', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 新增角色权限
	 * @param {*} [data={}]
	 * @returns
	 */
	addRole(data = {}) {
		return fetch('/v1/user/staff_role/add', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 删除角色权限
	 * @param {string} [id=""]
	 * @returns
	 */
	deleteRole(id = '') {
		return fetch('/v1/user/staff_role/delete', {
			params: { id }
		})
	},

	/**
	 * @desc 获取账号列表
	 * @param {*} [data={}]
	 * @returns
	 */
	getAccountList(data = {}) {
		return fetch('/v1/user/staff/staff_list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 新增账号
	 * @param {*} [data={}]
	 * @returns
	 */
	addAccount(data = {}) {
		return fetch('/v1/user/staff/add', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 更新账号
	 * @param {*} [data={}]
	 * @returns
	 */
	updateAccount(data = {}) {
		return fetch('/v1/user/staff/update', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 更改账号密码
	 * @param {*} [data={}]
	 * @returns
	 */
	changePwd(data = {}) {
		return fetch('/v1/user/staff/change_password', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 删除用户
	 * @param {string} [staffId=""]
	 * @returns
	 */
	deleteAccount(staffId = '') {
		return fetch('/v1/user/staff/delete', {
			params: { staffId }
		})
	},

	/**
	 * @desc 重置用户密码
	 * @param {string} [staffId='']
	 * @returns
	 */
	resetPwd(staffId = '') {
		return fetch('/v1/user/staff/re_set_password', {
			params: { staffId }
		})
	},

	/**
	 * @desc 获取消息通知管理列表
	 * @param {*} [data={}]
	 * @returns
	 */
	getNotificationList(data = {}) {
		return fetch('/v2/user/bms/official/notification/list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 获取消息通知详情
	 * @param {string} [notificationId='']
	 * @returns
	 */
	getNotificationDetail(notificationId = '') {
		return fetch(`/v2/user/bms/official/notification/info/${notificationId}`)
	},

	/**
	 * @desc 新增消息通知
	 * @param {*} [data={}]
	 * @returns
	 */
	addNotification(data = {}) {
		return fetch('/v2/user/bms/official/notification/add', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 编辑消息通知
	 * @param {*} [data={}]
	 * @returns
	 */
	editNotification(data = {}) {
		return fetch('/v2/user/bms/official/notification/edit', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 删除消息通知
	 * @param {string} [notificationId=""]
	 * @returns
	 */
	deleteNotification(notificationId = '') {
		return fetch(`/v2/user/bms/official/notification/edit/${notificationId}`, {
			method: 'DELETE'
		})
	},

	/**
	 * @desc 批量升级2680
	 * @param {*} [params={}]
	 * @returns
	 */
	batchUpdateUserRole(params = {}) {
		return fetch('/v1/distribution/bms/operator/batch_update_user_role', {
			method: 'POST',
			data: { ...params }
		})
	}
}

export default RoleService
