import fetch from '../fetch'

const CommonService = {
	/**
	 * @desc 登录
	 * @param phone
	 * @param password
	 * @returns {Promise}
	 */
	processLogin({ phone = '', password = '' }) {
		return fetch('/v1/user/staff_login', {
			method: 'POST',
			data: { phone, password }
		})
	},

	/**
	 * @desc 获取用户信息
	 * @param {*} [data={}]
	 * @returns
	 */
	getUserList(data = {}) {
		return fetch('/v2/user/user/user_list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 获取用户详情
	 * @param {string} [userId=""]
	 * @returns
	 */
	getUserDetail(userId = '') {
		return fetch('/v2/user/user/get_user_info_detail', {
			params: { userId }
		})
	},

	/**
	 * @desc 获取回复列表
	 * @param {*} [data={}]
	 * @returns
	 */
	getReplyList(data = {}) {
		return fetch('/v1/user/bms/social/talk_talk_all/list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 删除说说
	 * @param {string} [id=""]
	 * @returns
	 */
	deletePost(id = '') {
		return fetch(`/v1/user/bms/social/delete_post/${id}`, {
			method: 'DELETE'
		})
	},

	/**
	 * @desc 删除话题回复
	 * @param {string} [id=""]
	 * @returns
	 */
	deleteTopic(id = '') {
		return fetch(`/v1/user/bms/social/delete_topic_reply/${id}`, {
			method: 'DELETE'
		})
	},

	/**
	 * @desc 获取用户订阅卡相关记录（退卡管理）
	 * @param {string} [phone='']
	 * @returns
	 */
	getUserRelationList(phone = '') {
		return fetch('/v2/user/bms/return_card/user_card_relation_list', {
			params: { phone }
		})
	},

	/**
	 * @desc 退卡
	 * @param {*} [params={}]
	 * @returns
	 */
	returnCard(params = {}) {
		return fetch('/v1/user/bms/return_card/return_card', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 取消激活
	 * @param {*} [params={}]
	 * @returns
	 */
	cancelActivateCard(params = {}) {
		return fetch('/v1/user/bms/return_card/return_to_un_activate', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 允许用户临时确认书袋
	 * @param {string} [userId='']
	 * @returns
	 */
	allowConfirm(userId = '') {
		return fetch(`/v2/user/user/allow_confirm/${userId}`)
	},

	/**
	 * @desc 上传图片公共方法
	 * @param {*} [data={}]
	 * @returns
	 */
	uploadImg(data = {}) {
		return fetch('/v1/thirdparty/ali/upload_image', {
			method: 'POST',
			data: { ...data },
			headers: {
				authorization: 'multipart/form-data',
				'Content-Type': 'multipart/form-data'
			}
		})
	},

	/**
	 * @description 上传绘本页面图片
	 * @param prams
	 * @returns {*}
	 */
	updateHeicImage(prams = {}) {
		return fetch(`/v1/thirdparty/ali/upload_heic_image`, {
			method: 'POST',
			data: { ...prams }
		})
	},

	/**
	 * @desc 根据userId 获取account列表
	 * @param {string} [userId='']
	 * @returns
	 */
	getAccountListByUserId(userId = '') {
		return fetch('/v1/user/user/account_list', {
			params: { userId }
		})
	},

	/**
	 * @description 换绑微信
	 * @param userId
	 * @param accountId
	 * @returns {*}
	 */
	unBindingPhone({ userId = '', accountId = '' } = {}) {
		return fetch('/v2/user/user/unbinding_wechat', {
			params: { userId, accountId }
		})
	},

	/**
	 * @desc 换绑手机号
	 * @param {*} [{ userId = '', phone = '' }={}]
	 * @returns
	 */
	changePhoneByUserId({ userId = '', phone = '' } = {}) {
		return fetch('/v1/user/user/change_phone', {
			params: { userId, phone }
		})
	},

	/**
	 * @desc 强制换绑手机号
	 * @param {*} params
	 */
	cancelPhone(params = {}) {
		return fetch('/v1/user/user/force_change_phone', {
			params: { ...params }
		})
	},

	/**
	 * @desc 增加暂停次数
	 * @param {string} [userServiceCardId='']
	 * @returns
	 */
	addStopCount(userServiceCardId = '') {
		return fetch('/v1/user/user/incr_stop_count', {
			params: { userServiceCardId }
		})
	},

	/**
	 * @desc OSS 获取签名
	 * @param path
	 * @returns {undefined}
	 */
	getOSSData(path = '') {
		return fetch('/v1/thirdparty/common/get_upload_path_url', {
			params: { path }
		})
	},

	/**
	 * @desc 获取创作家帖子列表
	 * @param {*} [params={}]
	 * @returns
	 */
	fictionistList(params = {}) {
		return fetch('/v2/user/create_write_manage/post_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取创作家帖子详情
	 * @param {string} [postId='']
	 * @returns
	 */
	fictionistDetail(postId = '') {
		return fetch('/v2/user/create_write_manage/post_detail', {
			params: { postId }
		})
	},

	/**
	 * @desc 创作家状帖子态更改 operateType => 1: 新增, 2: 编辑, 3: 发布, 4: 下架
	 * @param {*} [params={}]
	 * @returns
	 */
	fictionistOperate(params = {}) {
		return fetch('/v2/user/create_write_manage/post_operate', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取创作家列表
	 * @param {*} [params={}]
	 * @returns
	 */
	getCreateWriteList(params = {}) {
		return fetch('/v1/user/create_write_manage/create_write_list', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @decs 编辑创作家帖子正文内容
	 * @param {*} [params={}]
	 * @returns
	 */
	postDetailModify(params = {}) {
		return fetch('/v1/user/create_write_manage/post_detail_modify', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 创作家编辑
	 * @param {*} [params={}]
	 * @returns
	 */
	createWriteOperate(params = {}) {
		return fetch('/v1/user/create_write_manage/create_write_operate', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取创作家标签
	 * @param tagName
	 * @returns {*}
	 */
	getCreateWriteTagList(tagName = '') {
		return fetch('/v1/user/create_write_manage/tag_list', {
			params: { tagName }
		})
	},

	/**
	 * @description 获取全部创作家标签
	 * @returns {*}
	 * @param ageRange
	 */
	getCreateWriteFirstTagList(ageRange = '') {
		return fetch('/v2/user/create_write_manage/get_first_tag_list', {
			params: { ageRange }
		})
	},

	/**
	 * @description 更新创作家标签
	 * @returns {*}
	 * @param params
	 */
	updateCreateWriteTagList(params = {}) {
		return fetch('/v2/user/create_write_manage/set_first_tag_by_age', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取当前创作家标签
	 * @returns {*}
	 * @param ageRange
	 */
	getCurrentCreateWriteTagList(ageRange = '') {
		return fetch('/v2/user/create_write_manage/get_first_tag_by_age', {
			params: { ageRange }
		})
	},

	/**
	 * @desc 转增2680名额
	 * @returns {undefined}
	 */
	extraTutor(params = {}) {
		return fetch('/v1/user/user/extra_tutor', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取用户操作日志
	 * @param params
	 * @returns {undefined}
	 */
	getUserOperationLog(params = {}) {
		return fetch('/v2/user/user/user_operation_log', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取圈子列表（审核）
	 * @param params
	 * @returns {undefined}
	 */
	getCircleList(params = {}) {
		return fetch('/v1/user/circle/get_circle_enroll_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 提交圈子审核
	 * @param params
	 * @returns {undefined}
	 */
	commitCircleCheckRes(params = {}) {
		return fetch('/v1/user/circle/commit_check_res', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取仓库看板
	 * @returns {*}
	 */
	getStoreRedashList() {
		return fetch('/v1/user/staff/storage_redash')
	},

	/**
	 * @description 升级服务功能
	 * @param params
	 * @returns {*}
	 */
	updateServiceInTool(params = {}) {
		return fetch('/v1/user/user/add_fhsj', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 根据手机号查询卡库存记录
	 * @param phone
	 * @returns {*}
	 */
	getCardStockByPhone(phone = '') {
		return fetch(`/v1/user/user/card_stock/${phone}`)
	},

	/**
	 * @description 20张卡升级操作
	 * @param params
	 * @returns {*}
	 */
	update20CardsByCardId(params = {}) {
		const { userId, cardId, phone } = params
		return fetch(`/v1/user/user/buy_card_upgrade_role/${userId}`, {
			params: { cardId, phone }
		})
	},
	/**
	 * @description 对公转账发卡
	 * @param {*} params
	 */
	getPublicTransferCard(params = {}) {
		return fetch('/v1/user/user/public_transfer_card', {
			params: { ...params }
		})
	},
	/**
	 * @description 更新圈子信息
	 * @param {*} params
	 * @returns
	 */
	updateCircle(params = {}) {
		return fetch('/v1/user/circle/update_circle', {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @description 获取所有的下载任务
	 * @param {*} params
	 */
	getDownloadTask(params = {}) {
		return fetch('/v1/thirdparty/ali/update_down_load_task', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取圈子活动列表
	 * @param params
	 * @returns {*}
	 */
	getCircleActivityList(params = {}) {
		return fetch('/v1/user/circle/get_circle_activity_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 提交圈子审核结果
	 * @param params
	 * @returns {*}
	 */
	commitCircleTopic(params = {}) {
		return fetch('/v1/user/circle/commit_circle_topic', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取用户抽奖次数
	 * @param params
	 * @returns {*}
	 */
	userLotteryCountList(params = {}) {
		return fetch('/v1/user/bms/activity/user_lottery_count', {
			params: { ...params }
		})
	},

	/**
	 * @description 扣减指定行为的抽奖次数
	 * @param params
	 * @returns {*}
	 */
	deductionUserLotteryCount(params = {}) {
		return fetch('/v1/user/bms/activity/deduction_user_lottery_count', {
			params: { ...params }
		})
	},

	/**
	 * @description 延长服务周期
	 * @param params
	 * @returns {*}
	 */
	extendExpiredTime(params = {}) {
		return fetch('/v2/user/bms/user_service/extend_expired_time', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新圈子活动
	 * @param params
	 * @returns {*}
	 */
	updateCircleActive(params = {}) {
		return fetch('/v1/user/circle/update_circle_active', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 查询机构
	 * @param params
	 */
	searchOrganization(params = {}) {
		return fetch('/v1/user/circle/bms_search_organization?', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取订阅卡记录列表
	 * @param data
	 * @returns {Promise}
	 */
	getUserServiceList(data = {}) {
		return fetch('/v2/user/bms/user_service/list', {
			params: { ...data }
		})
	},

	/**
	 * @description 获取用户服务暂停记录
	 * @param userId
	 * @returns {*}
	 */
	getUserPauseRecord(userId = '') {
		return fetch(`/v2/user/user/user_pause_record/${userId}`)
	},

	/**
	 * @description 通过手机号搜索用户
	 * @param phone
	 * @returns {*}
	 * @constructor
	 */
	SearchUserByPhone(phone) {
		return fetch(`/v2/user/user/search/${phone}`)
	},

	/**
	 * @description 获取配置信息
	 */
	getConfigDetailInfo(queryType = '') {
		return fetch(`/v1/user/p/my_info/config_detail_info`, {
			params: { queryType }
		})
	},

	/**
	 * @description 延期记录
	 * @param userServiceCardId
	 * @returns {*}
	 */
	getExtendRecordList(userServiceCardId = '') {
		return fetch(`/v1/user/bms/user_service/extend_record/${userServiceCardId}`)
	},

	/**
	 * @description 部分退款
	 * @param params
	 * @returns {*}
	 */
	returnCardPartRefund(params = {}) {
		return fetch(`/v2/user/bms/return_card/part_refund`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 新服务退款
	 * @param params
	 * @returns {*}
	 */
	returnNewServicePartRefund(params = {}) {
		return fetch(`/v2/user/bms/return_card/new_service_part_refund`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取用户领卡记录
	 * @param userId
	 * @returns {*}
	 */
	getTransferCardRecord(userId = '') {
		return fetch(`/v2/user/user/get_transfer_card_record/${userId}`)
	},

	/**
	 * @description 清缓存
	 * @returns {*}
	 */
	clearSystemConfigRedis() {
		return fetch(`/v2/user/config/clear_system_config_redis`, {
			method: 'POST'
		})
	},

	/**
	 * @description 获取config配置
	 * @param params
	 * @returns {*}
	 */
	getSearchInfo(params = {}) {
		return fetch(`/v2/user/config/query_config_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新config配置
	 * @param params
	 * @returns {*}
	 */
	editSearchInfo(params = {}) {
		return fetch(`/v2/user/config/edit_config`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加机构
	 * @param params
	 * @returns {*}
	 */
	addOrganizationInfo(params = {}) {
		return fetch(`/v1/user/organization/add`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取机构详情
	 * @param id
	 * @returns {*}
	 */
	getOrganizationDetail(id = '') {
		return fetch(`/v1/user/organization/detail`, {
			params: { id }
		})
	},

	/**
	 * @description  获取机构列表
	 * @param params
	 * @returns {*}
	 */
	getOrganizationList(params = {}) {
		return fetch(`/v1/user/organization/get_organization_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 下架机构
	 * @returns {*}
	 * @param orgId
	 */
	removeOrganization(orgId = '') {
		return fetch(`/v1/user/organization/remove`, {
			params: { orgId }
		})
	},

	/**
	 * @description  上架机构
	 * @returns {*}
	 * @param orgId
	 */
	putAwayOrganization(orgId = '') {
		return fetch(`/v1/user/organization/put_away`, {
			params: { orgId }
		})
	},

	/**
	 * @description  更新机构
	 * @param params
	 * @returns {*}
	 */
	updateOrganization(params = {}) {
		return fetch(`/v1/user/organization/update`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取用户服务信息
	 * @param userId
	 * @returns {*}
	 */
	getUserServiceInfo(userId = '') {
		return fetch(`/v2/user/user/get_user_service_relation/${userId}`)
	},

	/**
	 * 获取用户token
	 * @param userId
	 * @returns {*}
	 */
	getTokenInfo(userId = '') {
		return fetch(`/v2/user/user/get_user_t`, {
			params: { userId }
		})
	},

	/**
	 * @description 获取关系记录列表
	 * @param userId
	 * @returns {*}
	 */
	getUserReferRecord(userId = '') {
		return fetch('/v2/user/user/get_user_refer_record', {
			params: { userId }
		})
	},

	/**
	 * @description 获取短信发送记录
	 * @param params
	 * @returns {*}
	 */
	getSendInfoList(params = {}) {
		return fetch(`/v2/user/bms/notify/sms/query_send_record`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 发送短信
	 * @param params
	 * @returns {*}
	 */
	sendSmsInfo(params = {}) {
		return fetch(`/v2/user/bms/notify/sms/send`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 用户昵称搜索
	 * @param params
	 * @returns {*}
	 */
	searchUserByName(params = {}) {
		return fetch('/v2/user/user/user_search_by_name', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取白名单列表
	 * @param params
	 * @returns {*}
	 */
	getWhiteList(params = {}) {
		return fetch('/v2/user/bms/white_list/get_white_list_by_params', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取一级白名单类型
	 * @returns {*}
	 */
	getWhiteAllTypeList() {
		return fetch('/v2/user/bms/white_list/get_all_type')
	},

	/**
	 * @description 获取二级白名单类型
	 * @returns {*}
	 */
	getWhiteSecondTypeList(params = {}) {
		return fetch('/v2/user/bms/white_list/get_all_second_type', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description  删除白名单
	 * @returns {*}
	 * @param params
	 */
	deleteWhiteUser(params = {}) {
		return fetch('/v2/user/bms/white_list/del_white_list_by_id', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 删除白名单类型
	 * @param params
	 * @returns {*}
	 */
	deleteWhiteType(params = {}) {
		return fetch('/v2/user/bms/white_list/del_type', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加白名单类型
	 * @param params
	 * @returns {*}
	 */
	addWhiteType(params = {}) {
		return fetch('/v2/user/bms/white_list/add_type', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加白名单类型
	 * @param params
	 * @returns {*}
	 */
	addWhiteListType(params = {}) {
		return fetch('/v2/user/bms/white_list/add_white_list_by_type', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取制作实体卡列表
	 * @param params
	 * @returns {*}
	 */
	getCardUserList(params = {}) {
		return fetch('/v2/user/card_stock/user_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 根据用户id获取库存记录
	 * @param params
	 * @returns {*}
	 */
	getCardStockRecord(params = {}) {
		return fetch('/v2/user/card_stock/card_stock', {
			params: { ...params }
		})
	},

	/**
	 * @description 查看转卡、制作实体卡记录
	 * @param params
	 * @returns {*}
	 */
	getTransferCardDetail(params = {}) {
		return fetch('/v2/user/card_stock/get_transfer_card_record', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 实体卡制作
	 * @param params
	 * @returns {*}
	 */
	getTransferCard(params = {}) {
		return fetch('/v2/user/card_stock/transfer_card', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description
	 * @param params
	 * @returns {*}
	 */
	getSearchWhiteListType(params = {}) {
		return fetch('/v2/user/bms/white_list/search_white_list_type', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description  新增描述
	 * @param params
	 * @returns {*}
	 */
	addWhiteDesc(params = {}) {
		return fetch('/v2/user/bms/white_list/add_white_list_type', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description  修改描述
	 * @param params
	 * @returns {*}
	 */
	updateWhiteDesc(params = {}) {
		return fetch('/v2/user/bms/white_list/update_white_list_type', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description  修改描述
	 * @returns {*}
	 * @param id
	 */
	deleteWhiteDesc(id = '') {
		return fetch('/v2/user/bms/white_list/del_white_list_type', {
			params: { id }
		})
	},

	/**
	 * @description 买断谷兜兜
	 * @param params
	 * @returns {*}
	 */
	buyOutGdd(params) {
		return fetch('/v2/user/bms/user_service/buy_out_gdd', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 查询素材库1级标签
	 * @returns {*}
	 */
	libraryContentFirstTagSearch() {
		return fetch('/v2/user/library/library_content_first_tag_search', {
			method: 'POST'
		})
	},

	/**
	 * @description 查询素材库2级标签
	 * @param tagId
	 * @returns {*}
	 */
	libraryContentSecondTagSearch(tagId) {
		return fetch('/v2/user/library/library_content_second_tag_search', {
			method: 'POST',
			data: { tagId }
		})
	},

	/**
	 * @description 查询素材库列表
	 * @param params
	 * @returns {*}
	 */
	libraryContentSearch(params = {}) {
		return fetch('/v2/user/library/library_content_search', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加一级标签
	 * @param params
	 * @returns {*}
	 */
	libraryContentFirstTagAdd(params = {}) {
		return fetch('/v2/user/library/library_content_first_tag_add', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加二级标签
	 * @param params
	 * @returns {*}
	 */
	libraryContentSecondTagAdd(params = {}) {
		return fetch('/v2/user/library/library_content_second_tag_add', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 素材库数据上传
	 * @param params
	 * @returns {*}
	 */
	libraryContentUpload(params = {}) {
		return fetch('/v2/user/library/library_content_upload', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 素材库数据更新
	 * @param params
	 * @returns {*}
	 */
	libraryContentUpdate(params = {}) {
		return fetch('/v2/user/library/library_content_update', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 申请服务暂停
	 * @param {*} params
	 * @returns
	 */
	servicePausedApply(params) {
		return fetch('/v2/user/bms/user_service/apply', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 恢复服务暂停
	 * @param {*} params
	 * @returns
	 */
	servicePausedRecover(params) {
		return fetch('/v2/user/bms/user_service/recover', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 销售商品订阅卡
	 * @param {*} params
	 * @returns
	 */
	getUserCardPage(params) {
		return fetch('/v2/user/bms/card/page', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 公共导出
	 * @param {*} params
	 * @returns
	 */
	commonExport(params = {}) {
		return fetch('/v1/thirdparty/bms/export', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新素材状态
	 * @param {*} params
	 * @returns
	 */
	updateContentStatus(params = {}) {
		return fetch('/v2/user/library/update_content_status', {
			method: 'POST',
			data: { ...params }
		})
	}
}

export default CommonService
