import { CommonService } from '../../libs/api'

class WhiteList {
  handleUpdate = async (params) => {
    return await CommonService.updateServiceInTool(params)
  }
}

const WhiteListStore = new WhiteList()

export { WhiteListStore }
