import fetch from '../fetch'

const OrderService = {
	/**
	 * @desc 发货审单筛选
	 * @returns {Promise}
	 */
	getWaitDeliveryItemList() {
		return fetch('/v2/order/bms/sale_order/wait_delivery/item_list')
	},

	/**
	 * @desc 根据物流单号查询订单信息
	 * @returns {Promise}
	 * @param params
	 */
	updateExpressOrder(params = {}) {
		return fetch('/v2/order/bms/express/update/code', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 根据物流单号查询订单信息
	 * @returns {Promise}
	 * @param params
	 */
	getExpressOrder(params = {}) {
		return fetch('/v2/order/bms/express/order', {
			params: { ...params }
		})
	},

	/**
	 * @desc 更新规格
	 * @returns {Promise}
	 */
	getItemSkuTable(params = {}) {
		return fetch('/v2/order/bms/item/get_item_sku_table', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新规格
	 * @returns {Promise}
	 */
	updateSkuItem(params = {}) {
		return fetch('/v2/order/bms/item/update_item_sku', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新价格
	 * @returns {Promise}
	 */
	updateSkuPrice(params = {}) {
		return fetch('/v2/order/bms/item_point/update_sku', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取部分退款详情
	 * @returns {Promise}
	 */
	getEduOrderRefundInfo(params = {}) {
		return fetch('/v2/order/edu_deduct_order/edu_order_refund_info', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取积分订单类型列表
	 * @returns {Promise}
	 */
	updatePointCatList() {
		return fetch('/v2/order/bms/item_point/cat_list')
	},

	/**
	 * @desc 更新积分商品状态
	 * @returns {Promise}
	 * @param params
	 */
	updatePointGoodsSaleRule(params = {}) {
		return fetch('/v2/order/bms/item_point/update_sale_rule', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新积分商品价格
	 * @returns {Promise}
	 * @param params
	 */
	updatePointGoodsPrice(params = {}) {
		return fetch('/v2/order/bms/item_point/update_price', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新积分商品信息
	 * @returns {Promise}
	 * @param params
	 */
	updatePointGoodsInfo(params = {}) {
		return fetch('/v2/order/bms/item_point/update_info', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 积分商品详情
	 * @returns {Promise}
	 * @param itemPointId
	 */
	getPointPageDetail(itemPointId = '') {
		return fetch('/v2/order/bms/item_point/detail', {
			params: { itemPointId }
		})
	},

	/**
	 * @desc 积分商品列表
	 * @returns {Promise}
	 * @param params
	 */
	getPointPageList(params = {}) {
		return fetch('/v2/order/bms/item_point/page', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 添加多规格商品
	 * @returns {Promise}
	 * @param params
	 */
	addSpuGoods(params = {}) {
		return fetch('/v2/order/bms/item/add_spu', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 查询规格属性
	 * @returns {Promise}
	 * @param params
	 */
	getSkuProperty(params = {}) {
		return fetch('/v2/order/bms/item/get_sku_property', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取订阅卡订单详情
	 * @returns {Promise}
	 * @param params
	 */
	getUserCouponPage(params = {}) {
		return fetch('/v2/order/bms/user_coupon/page/coupon', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取订阅卡订单详情
	 * @param data
	 * @returns {Promise}
	 */
	getUserServiceCardDetail(data = {}) {
		return fetch('/v2/order/bms/bag_order/list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 冻结订阅卡
	 * @param data
	 * @returns {Promise}
	 */
	freezeOrder(data = {}) {
		return fetch('/v1/user/user/service/suspend', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 冻结订阅卡
	 * @param data
	 * @returns {Promise}
	 */
	notFreezeOrder(data = {}) {
		return fetch('/v1/user/user/service/recover', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 书袋订单列表
	 * @param data
	 * @returns {Promise}
	 */
	getBagOrderList(data = {}) {
		return fetch('/v2/order/bms/bag_order/bag_list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 获取书袋详情信息
	 * @returns {Promise}
	 * @param bagOrderId
	 */
	getBagOrderDetail(bagOrderId = '') {
		return fetch(`/v2/order/bms/bag_order/bag_detail/${bagOrderId}`)
	},

	/**
	 * @desc 获取书袋详情中所有子订单的列表
	 * @param bagOrderId
	 * @returns {Promise}
	 */
	getBagSubOrderList(bagOrderId = '') {
		return fetch(`/v2/order/bms/bag_order/sub_bag_detail/${bagOrderId}`)
	},

	/**
	 * @desc 更新书袋逾期单
	 * @returns {Promise}
	 * @param data
	 */
	updateOverdueTime(data = {}) {
		return fetch('/v2/order/bms/bag_order/update_overdue_date', {
			method: 'PUT',
			data: { ...data }
		})
	},

	/**
	 * @desc 获取所有的关联订单
	 * @param bagOrderId
	 * @returns {Promise}
	 */
	getLinkedOrder(bagOrderId = '') {
		return fetch('/v2/order/bms/bag_order/get_all_linked_order', {
			params: { bagOrderId }
		})
	},

	/**
	 * @desc 获取追损列表
	 * @param data
	 * @returns {Promise}
	 */
	getDamageList(data = {}) {
		return fetch('/v2/order/bms/damage_order/list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 获取追损详情
	 * @param damageOrderId
	 * @returns {Promise}
	 */
	getDamageInfo(damageOrderId = '') {
		return fetch('/v2/order/bms/damage_order/detail', {
			params: { damageOrderId }
		})
	},

	/**
	 * @desc 获取追损详情
	 * @returns {Promise}
	 * @param params
	 */
	pointsPayDamageOrder(params = {}) {
		return fetch('/v2/order/bms/damage_order/points_pay_damage_order', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新追损单subOrder
	 * @param data
	 * @returns {Promise}
	 */
	updateDamageInfo(data = {}) {
		return fetch('/v2/order/bms/damage_order/update', {
			method: 'PUT',
			data: { ...data }
		})
	},

	/**
	 * @desc 获取报损详情列表
	 * @param damageReportId
	 * @returns {Promise}
	 */
	getReportDetail(damageReportId = '') {
		return fetch('/v2/order/bms/damage_report/detail', {
			params: { damageReportId }
		})
	},

	/**
	 * @desc 提前结束服务
	 * @returns {Promise}
	 * @param userId
	 */
	overService(userId = '') {
		return fetch('/v2/order/edu_deduct_order/over_service', {
			params: { userId }
		})
	},

	/**
	 * @desc 获取入库单列表
	 * @param data
	 * @returns {undefined}
	 */
	getGoodsInOrderList(data = {}) {
		return fetch('/v2/order/bms/goods_in_order/goods_in_list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 获取入库单详情
	 * @param goodsInOrderId
	 * @returns {Promise}
	 */
	getGoodsInOrderDetail(goodsInOrderId = '') {
		return fetch('/v2/order/bms/goods_in_order/detail', {
			params: { goodsInOrderId }
		})
	},

	/**
	 * @desc 获取回收订单列表
	 * @param data
	 * @returns {Promise}
	 */
	getReturnOrderList(data = {}) {
		return fetch('/v2/order/bms/return_order/return_list', {
			params: { ...data }
		})
	},

	/**
	 * @desc 获取回收单详情
	 * @param returnOrderId
	 * @returns {Promise}
	 */
	getReturnOrderDetail(returnOrderId = '') {
		return fetch('/v2/order/bms/return_order/detail', {
			params: { returnOrderId }
		})
	},

	/**
	 * @desc 验收入库
	 * @param data
	 * @returns {Promise}
	 */
	addGoodsIn(data = {}) {
		return fetch('/v2/order/bms/goods_in_order/inspect', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 新增追损
	 * @param data
	 * @returns {Promise}
	 */
	addDamageOrder(data = {}) {
		return fetch('/v2/order/bms/damage_order/add', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 获取购买订单
	 * @param {string} [purchaseOrderId=""]
	 * @returns {Promise}
	 */
	getPurchaseDetail(purchaseOrderId = '') {
		return fetch('/v2/order/bms/purchase_order/detail', {
			params: { purchaseOrderId }
		})
	},

	/**
	 * @desc 取消预约
	 * @param {string} [orderCode=""]
	 * @returns
	 */
	cancelAppointment(orderCode = '') {
		return fetch(
			`/v2/order/bms/return_order/cancel_appointment_v2/${orderCode}`
		)
	},

	/**
	 * @desc 取消追损订单
	 * @param {string} [damageOrderId=""]
	 * @returns
	 */
	cancelDamageOrder(damageOrderId = '') {
		return fetch(
			`/v2/order/bms/damage_order/cancel_damage_order/${damageOrderId}`,
			{
				method: 'PUT'
			}
		)
	},

	/**
	 * @desc 取消单独的绘本追损
	 * @param {string} [damageSubOrderId=""]
	 * @returns
	 */
	cancelDamageSubOrder(damageSubOrderId = '') {
		return fetch(
			`/v2/order/bms/damage_order/cancel_damage_sub_order/${damageSubOrderId}`,
			{
				method: 'PUT'
			}
		)
	},

	/**
	 * @desc 更新追损折扣金额（用于追损打折）
	 * @param {*} [params={}]
	 * @returns
	 */
	updateDamageOrderDiscount(params = {}) {
		return fetch('/v2/order/bms/damage_order/update_damage_order_discount', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 手动更新
	 * @param {*} [data={}]
	 * @returns
	 */
	manualSignIn(data = {}) {
		return fetch('/v2/order/bms/return_order/manual_sign_in', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 获取押金列表
	 * @param {*} [data={}]
	 * @returns
	 */
	getDepositOrderList(data = {}) {
		return fetch('/v2/order/bms/deposit_order/list_v2', {
			params: { ...data }
		})
	},

	/**
	 * @description 获取申请解绑支付分列表
	 * @param params
	 * @returns {*}
	 */
	getDepositApplyList(params = {}) {
		return fetch('/v2/order/bms/deposit_order/apply_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取押金详情
	 * @param {string} [orderId='']
	 * @returns
	 */
	getDepositOrderDetail(orderId = '') {
		return fetch(`/v2/order/bms/deposit_order/${orderId}`, {
			method: 'POST'
		})
	},

	/**
	 * @desc 完成押金订单
	 * @param {*} [data={}]
	 * @returns
	 */
	completeWithWxScore(data = {}) {
		return fetch('/v2/order/bms/deposit_order/complete_with_wx_score', {
			method: 'POST',
			data: { ...data }
		})
	},

	completeWithWxPay(data = {}) {
		return fetch('/v2/order/bms/deposit_order/complete_with_wx_pay', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 复核
	 * @param {*} [data={}]
	 * @returns
	 */
	reCheckOrder(data = {}) {
		return fetch('/v2/order/bms/goods_in_order/re_check', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 复核列表V2
	 * @param {*} [data={}]
	 * @returns
	 */
	reCheckListV2(data = {}) {
		return fetch('/v1/order/bms/goods_in_order/re_check_listV2', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 提交复核
	 * @param {*} [data={}]
	 * @returns
	 */
	submitReCheck(data = {}) {
		return fetch('/v2/order/bms/goods_in_order/submit_re_check', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 确认质检复核
	 * @param {*} [data={}]
	 * @returns
	 */
	confirmReCheck(data = {}) {
		return fetch('/v2/order/bms/goods_in_order/confirm_re_check', {
			method: 'POST',
			data: { ...data }
		})
	},

	/**
	 * @desc 获取审核发单列表
	 * @param {*} [data={}]
	 * @returns
	 */
	getAuditBagList(data = {}) {
		return fetch('/v2/order/bms/bag_order/audit_bag_List', {
			params: { ...data }
		})
	},

	/**
	 * @desc 审单详情
	 * @param {string} [bagOrderId='']
	 * @returns
	 */
	getAuditBagDetail(bagOrderId = '') {
		return fetch(`/v2/order/bms/bag_order/audit_bag_info/${bagOrderId}`)
	},

	/**
	 * @desc 确认审单
	 * @param {*} [params={}]
	 * @returns
	 */
	confirmAudit(params = {}) {
		return fetch('/v2/order/bms/bag_order/audit_bag_confirm', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 审单标记异常
	 * @param {*} [params={}]
	 * @returns
	 */
	markUnusual(params = {}) {
		return fetch('/v2/order/bms/bag_order/audit_bag_unusual', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 批量确认审单
	 * @param {*} [params={}]
	 * @returns
	 */
	batchCauditBagConfirm(params = {}) {
		return fetch('/v2/order/bms/bag_order/batch_caudit_bag_confirm', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 允许用户重新报损
	 * @param {*} bagOrderId
	 */
	anewReport(bagOrderId = '') {
		return fetch(`/v2/order/bms/bag_order/anew_report/${bagOrderId}`)
	},

	/**
	 * @desc 手动生成下一个书袋
	 * @param {*} serviceCardId
	 */
	manualCatchNextBag(serviceCardId = '') {
		return fetch('/v2/order/bms/bag_order/bagOrder/manual_catch_next_bag', {
			params: { serviceCardId }
		})
	},

	/**
	 * @desc 获取自寄单列表
	 * @param {*} [params={}]
	 * @returns
	 */
	getSelfReturnOrderList(params = {}) {
		return fetch('/v2/order/bms/selfReturnOrder/return_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 批量完成自寄订单
	 * @param {*} [params={}]
	 * @returns
	 */
	completeSelfOrder(params = {}) {
		return fetch('/v2/order/bms/selfReturnOrder/complete_self_order', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 导出自寄订单
	 * @param {*} [params={}]
	 * @returns
	 */
	exportShelfReturnExl(params = {}) {
		return fetch('/v2/order/bms/selfReturnOrder/export_return_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 根据日期获取2680未升级用户列表
	 * @param {string} [date='']
	 * @returns
	 */
	getUnUpgradeRegisterUser(date = '') {
		return fetch('/v1/order/bms/register_order/un_upgrade_register_user', {
			params: { date }
		})
	},

	/**
	 * @desc 获取用户积分兑换记录
	 *
	 * @param {*} [params={}]
	 * @returns
	 */
	getUserPointsDetail(params = {}) {
		return fetch('/v2/order/bms/point/v1/user/points/detail', {
			params: { ...params }
		})
	},

	/**
	 * @desc 购买订单退款
	 * @param {string} [purchaseOrderId='']
	 * @returns
	 */
	refundPurchaseOrder(purchaseOrderId = '') {
		return fetch('/v2/order/bms/purchase_order/refund_purchase_order', {
			params: { purchaseOrderId }
		})
	},

	/**
	 * @desc 审单详情单独保存用户地址
	 *
	 * @param {*} [params={}]
	 * @returns
	 */
	saveUserAddressInCheckDeliver(params = {}) {
		return fetch('/v2/order/bms/bag_order/save_user_address', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 批量完结押金单
	 * @param {*} [params={}]
	 * @returns
	 */
	completeWithWxScoreBatch(params = {}) {
		return fetch('/v2/order/bms/deposit_order/complete_with_wx_score_batch', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 2680 GET 退款
	 * @returns {undefined}
	 */
	campaignOrderRefund(campaignOrderId = '') {
		return fetch(
			`/v2/order/bms/campaign_order/campaign_order_refund/${campaignOrderId}`
		)
	},

	/**
	 * @desc 获取积分订单
	 * @param params
	 * @returns {undefined}
	 */
	getPointsOrderList(params = {}) {
		return fetch(`/v2/order/bms/point_order/list`, {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取提交追损单物流信息
	 * @param params
	 * @returns {undefined}
	 */
	updateTrackingNo(params = {}) {
		return fetch('/v2/order/bms/damage_order/update_tracking_no', {
			method: 'PUT',
			data: { ...params }
		})
	},

	/**
	 * @description  获取用户抽奖订单列表
	 * @param userId
	 * @returns {*}
	 */
	getUserLotteryOrderList(userId = '') {
		return fetch(
			`/v2/order/bms/order_monthly_activity/get_user_lottery_order_list`,
			{
				params: { userId }
			}
		)
	},

	/**
	 * @description  回收奖品
	 * @param orderCode
	 * @returns {*}
	 */
	recyclePrize(orderCode = '') {
		return fetch(`/v2/order/bms/order_monthly_activity/recycle_prize`, {
			params: { orderCode }
		})
	},

	/**
	 * @description 核销押金解绑订单
	 * @param orderId
	 * @returns {*}
	 */
	completeApplyDepositOrder(orderId = '') {
		return fetch(`/v2/order/bms/deposit_order/complete_apply_order/${orderId}`)
	},

	/**
	 * @description 获取积分订单审单列表
	 * @param params
	 * @returns {*}
	 */
	getAuditPointsOrderList(params = {}) {
		return fetch('/v2/order/bms/point_order/audit_points_order_List', {
			params: { ...params }
		})
	},

	/**
	 * @description 积分订单审单
	 * @param params
	 * @returns {*}
	 */
	auditPointsOrderConfirm(params = {}) {
		return fetch('/v2/order/bms/point_order/audit_points_order_confirm', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 积分订单审单标记异常
	 * @param params
	 * @returns {*}
	 */
	auditPointsOrderUnusual(params = {}) {
		return fetch('/v2/order/bms/point_order/audit_points_order_unusual', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取用户优惠券管理列表
	 * @param userId
	 * @param params
	 * @returns {*}
	 */
	getUserCouponList(userId = '', params = {}) {
		return fetch(`/v2/order/bms/user_coupon/list/${userId}`, {
			params: { ...params }
		})
	},

	/**
	 * @description 更新用户优惠券过期时间
	 * @param userCouponId
	 * @param expireTime
	 * @returns {*}
	 */
	updateUserCouponExtendExpired(userCouponId = '', expireTime = '') {
		return fetch(`/v2/order/bms/user_coupon/extend_expired/${userCouponId}`, {
			params: { expireTime }
		})
	},

	/**
	 * @description 恢复用户优惠券
	 * @param userCouponId
	 * @returns {*}
	 */
	recoverUserCoupon(userCouponId = '') {
		return fetch(`/v2/order/bms/user_coupon/recover_coupon/${userCouponId}`)
	},

	/**
	 * @description 获取物流错误信息
	 * @param orderCode
	 * @returns {*}
	 */
	getErrExpressMsg(orderCode = '') {
		return fetch(`/v2/order/bms/bag_order/err_express_msg/${orderCode}`)
	},

	/**
	 * @description 批量积分订单审单
	 * @param params
	 * @returns {*}
	 */
	batchAuditPointsOrderConfirm(params = {}) {
		return fetch(`/v2/order/bms/point_order/batch_audit_points_order_confirm`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 书袋添加备注
	 * @param params
	 * @returns {*}
	 */
	subBagAssRemark(params = {}) {
		return fetch(`/v2/order/bms/bag_order/sub_bag_add_remark`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 批量更新订单状态
	 * @param params
	 * @returns {*}
	 */
	updateOrderExpressCode(params = {}) {
		return fetch(
			`/v2/order/bms/physical_card_order/batch_update_order_express_code`,
			{
				params: { ...params }
			}
		)
	},

	/**
	 * @description 导出实体卡订单
	 * @param params
	 * @returns {*}
	 */
	exportPhysicalOrderList(params = {}) {
		return fetch(
			`/v2/order/bms/physical_card_order/export_physical_order_list`,
			{
				params: { ...params }
			}
		)
	},

	/**
	 * @description 实体卡订单列表
	 * @param params
	 * @returns {*}
	 */
	physicalCardList(params = {}) {
		return fetch(`/v2/order/bms/physical_card_order/physical_card_list`, {
			params: { ...params }
		})
	},

	/**
	 * @description 相关活动奖励
	 * @param code
	 * @returns {*}
	 */
	relateActivityAward(code = '') {
		return fetch(`/v2/order/bms/user_coupon/relate_activity_award/${code}`)
	},

	/**
	 * @description 订单退款检查
	 * @param orderCode
	 * @returns {*}
	 */
	checkOrderUserStatus(orderCode = '') {
		return fetch(
			`/v2/order/bms/campaign_order/get_activity_award_use_status/${orderCode}`
		)
	},

	/**
	 * @description 获取用户购卡订单列表
	 * @param userId
	 * @returns {*}
	 */
	getCardOrderList(userId = '') {
		return fetch(`/v2/order/bms/card_order/get_list/${userId}`)
	},

	/**
	 * @description 回归待确认
	 * @param bagOrderId
	 * @returns {*}
	 */
	cancelConfirm(bagOrderId = '') {
		return fetch(`/v2/order/bms/bag_order/cancel_confirm`, {
			params: { bagOrderId }
		})
	},

	/**
	 * @description 追损单子订单退款（可批量）
	 * @param params
	 * @returns {*}
	 */
	refundDamageSubOrder(params = {}) {
		return fetch(`/v2/order/bms/damage_order/refund_damage_sub_order`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 书袋已购买子订单退款(可批量)
	 * @param params
	 * @returns {*}
	 */
	refundPurchaseSubOrder(params = {}) {
		return fetch(`/v2/order/bms/purchase_order/refund_purchase_sub_order`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 工单记录列表
	 * @param params
	 * @returns {*}
	 */
	getTicketList(params = {}) {
		return fetch(`/v2/order/bms/ticket/get_ticket_by_type`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加工单跟进记录
	 * @param params
	 * @returns {*}
	 */
	addTicketRecord(params = {}) {
		return fetch(`/v2/order/bms/ticket/add_ticket_record`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 丢件记录查询
	 * @param params
	 * @returns {*}
	 */
	getLostTicketList(params = {}) {
		return fetch(`/v2/order/bms/ticket/get_lost_ticket`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 工单记录详情
	 * @returns {*}
	 * @param ticketId
	 */
	getTicketRecordList(ticketId = '') {
		return fetch(`/v2/order/bms/ticket/get_ticket_record_list`, {
			params: { ticketId }
		})
	},

	/**
	 * @description 记录丢件
	 * @param params
	 * @returns {*}
	 */
	changeLostOrderRecord(params = {}) {
		return fetch(`/v2/order/bms/ticket/lost_order_record`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 批量处理丢件订单
	 * @param params
	 * @returns {*}
	 */
	overTicketList(params = []) {
		return fetch(`/v2/order/bms/ticket/over_ticket`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 报损自订单回复
	 * @param params
	 * @returns {*}
	 */
	subReportReply(params = {}) {
		return fetch(`/v2/order/bms/damage_report/sub_report/reply`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 标记损坏分类
	 * @param params
	 * @returns {*}
	 */
	subReportDamageClassify(params = {}) {
		return fetch(
			`/v2/order/bms/damage_report/sub_report/mark_damage_classify`,
			{
				method: 'POST',
				data: { ...params }
			}
		)
	},

	/**
	 * @description 标记积分补偿
	 * @param params
	 * @returns {*}
	 */
	subReportPointCompensation(params = {}) {
		return fetch(
			`/v2/order/bms/damage_report/sub_report/mark_point_compensation`,
			{
				method: 'POST',
				data: { ...params }
			}
		)
	},

	/**
	 * @description 完成积分补偿
	 * @param params
	 * @returns {*}
	 */
	completePointsCompensation(params = {}) {
		return fetch(`/v2/order/bms/damage_report/complete_points_compensation`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 导出积分申请
	 * @param params
	 * @returns {*}
	 */
	exportPointsApply(params = {}) {
		return fetch(`/v2/order/bms/damage_report/points_apply_export`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 导出自订单
	 * @param params
	 * @returns {*}
	 */
	exportSubReport(params = {}) {
		return fetch(`/v2/order/bms/damage_report/sub_report_export`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 整单回复
	 * @param params
	 * @returns {*}
	 */
	damageReportV2(params = {}) {
		return fetch(`/v2/order/bms/damage_report/updateV2`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description  报损列表接口v3
	 * @param params
	 * @returns {*}
	 */
	getDamageReportListV3(params = {}) {
		return fetch(`/v2/order/bms/damage_report/damage_report_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 导入物流信息
	 * @param params
	 * @returns {*}
	 */
	importDamageOrder(params = {}) {
		return fetch(`/v2/order/bms/damage_order/update_tracking_no_by_import`, {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @description 导出追损单
	 * @param params
	 * @returns {*}
	 */
	exportDamageOrder(params = {}) {
		return fetch(`/v2/order/bms/damage_order/export`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 驳回到质检复核
	 * @param params
	 * @returns {*}
	 */
	reBackConfirm(params = {}) {
		return fetch(`/v2/order/bms/goods_in_order/confirm_re_back`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取质检复核详情
	 * @param params
	 * @returns {*}
	 */
	getReCheckDetail(params = {}) {
		return fetch(`/v2/order/bms/goods_in_order/re_check_detail`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description  导出工单
	 * @param params
	 * @returns {*}
	 */
	exportTicketList(params = {}) {
		return fetch(`/v2/order/bms/ticket/export`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 修改积分订单审单信息
	 * @param params
	 * @returns {*}
	 */
	editPointsInfo(params = {}) {
		return fetch(`/v2/order/bms/point_order/audit_points_order_info_update`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取扣费列表
	 * @param params
	 * @returns {*}
	 */
	getDeductRecordList(params = {}) {
		return fetch(`/v2/order/edu_deduct_order/get_deduct_record`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 扣费详情
	 * @param orderId
	 * @returns {*}
	 */
	getDeductDetail(orderId = '') {
		return fetch(`/v2/order/edu_deduct_order/get_deduct_order_detail`, {
			params: { orderId }
		})
	},

	/**
	 * @description 扣费退款
	 * @param params
	 * @returns {*}
	 */
	refundDeductOrder(params = {}) {
		return fetch(`/v2/order/edu_deduct_order/deduct_order_refund`, {
			data: { ...params },
			method: 'POST'
		})
	},

	refundReturnOrder(returnOrderId = '') {
		return fetch(`/v2/order/bms/return_order/refund`, {
			params: { returnOrderId }
		})
	},

	/**
	 * @description 强制取消快递预约
	 * @param params
	 * @returns {*}
	 */
	cancelReturnOrderForce(params = {}) {
		return fetch(`/v1/order/bms/return_order/force_cancel_return_order`, {
			params: { ...params }
		})
	},

	/**
	 * @description 更新收货地址
	 * @param params
	 * @returns {*}
	 */
	updateBagOrderAddress(params = {}) {
		return fetch(`/v2/order/bms/bag_order/update_bag_order_address`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 转谷粒
	 * @param params
	 * @returns {*}
	 */
	transferCereal(params = {}) {
		return fetch(`/v2/order/bms/cereal/cereal_transfer`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 转谷粒列表
	 * @param params
	 * @returns {*}
	 */
	transferCerealList(params = {}) {
		return fetch(`/v2/order/bms/cereal/get_cereal_transfer_record_list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 转谷粒详情
	 * @param params
	 * @returns {*}
	 */
	transferCerealDetail(id = '') {
		return fetch(`/v2/order/bms/cereal/get_cereal_transfer_record_detail`, {
			data: { id }
		})
	},

	/**
	 * @description 获取购买单列表
	 * @param params
	 * @returns {*}
	 */
	getPurchaseList(params = {}) {
		return fetch(`/v2/order/bms/purchase_order/list`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 异常工单获取徽标数
	 * @returns {*}
	 */
	getTicketTimeoutCount() {
		return fetch(`/v2/order/bms/ticket/get_ticket_timeout_count`)
	},

	/**
	 * @description 积分商城退款
	 * @param params
	 * @returns {*}
	 */
	refundPointOrder(params = {}) {
		return fetch(`/v2/order/bms/point_order/refund`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取积分订单详情
	 * @param orderId
	 * @returns {*}
	 */
	getPointOrderDetail(orderId = '') {
		return fetch(`/v2/order/bms/point_order/detail/${orderId}`)
	},

	/**
	 * @description 有赞商品管理
	 * @param params
	 * @returns {*}
	 */
	getSkuOrderList(params = {}) {
		return fetch(`/v2/order/bms/sku/page`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加商品管理
	 * @param params
	 * @returns {*}
	 */
	addSkuOrder(params = {}) {
		return fetch(`/v2/order/bms/sku/add`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新商品管理
	 * @param params
	 * @returns {*}
	 */
	editSkuOrder(params = {}) {
		return fetch(`/v2/order/bms/sku/update`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取有赞商品详情
	 * @param skuId
	 * @returns {*}
	 */
	getSkuOrderDetail(skuId = '') {
		return fetch(`/v2/order/bms/sku/detail`, {
			params: { skuId }
		})
	},

	/**
	 * @description 同步WMS
	 * @param skuId
	 * @returns {*}
	 */
	syncWMSGoods(params) {
		return fetch(`/v2/order/bms/sku/sync_wms`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取销售商品
	 * @returns {*}
	 * @param params
	 */
	getSaleInfoList(params = {}) {
		return fetch(`/v2/order/bms/item/page`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 查询销售渠道
	 * @returns {*}
	 * @param itemId
	 */
	getChannelDetail(itemId = '') {
		return fetch(`/v2/order/bms/item/get_item_channel`, {
			params: { itemId }
		})
	},

	/**
	 * @description 更新销售渠道
	 * @returns {*}
	 * @param params
	 */
	updateChannelStock(params = {}) {
		return fetch(`/v2/order/bms/item/update_item_channel_stock `, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新总库存
	 * @returns {*}
	 * @param params
	 */
	updateStock(params = {}) {
		return fetch(`/v2/order/bms/item/update_item_stock `, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 同步销售库存
	 * @param params
	 * @returns {*}
	 */
	syncChannelItem(params = {}) {
		return fetch(`/v2/order/bms/item/sync_item_channel`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 查询销售渠道
	 * @returns {*}
	 * @param itemId
	 */
	getChannelItem(itemId = '') {
		return fetch(`/v2/order/bms/item/get_item_channel`, {
			params: { itemId }
		})
	},

	/**
	 * @description 添加销售商品
	 * @param params
	 * @returns {*}
	 */
	addSaleGoodsItem(params = {}) {
		return fetch(`/v2/order/bms/item/add`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 添加组合销售商品
	 * @param params
	 * @returns {*}
	 */
	addSaleComposeGoodsItem(params = {}) {
		return fetch(`/v2/order/bms/item/add_compose`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 查询销售详情
	 * @returns {*}
	 * @param itemId
	 */
	getSaleGoodsDetail(itemId = '') {
		return fetch(`/v2/order/bms/item/detail`, {
			params: { itemId }
		})
	},

	/**
	 * @description 获取订单
	 * @returns {*}
	 * @param params
	 */
	getSaleOrderList(params = {}) {
		return fetch(`/v2/order/bms/sale_order/page`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 查询订单详情
	 * @returns {*}
	 * @param saleOrderId
	 */
	getSaleOrderDetail(saleOrderId = '') {
		return fetch(`/v2/order/bms/sale_order/detail`, {
			params: { saleOrderId }
		})
	},

	/**
	 * @description 获取销售库存记录
	 * @param params
	 * @returns {*}
	 */
	getStockRecord(params = {}) {
		return fetch(`/v2/order/bms/item/page_item_stock_record`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取发货审单列表
	 * @param params
	 * @returns {*}
	 */
	getSaleGoodsCheckList(params = {}) {
		return fetch(`/v2/order/bms/sale_order/audit/page`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 有赞销售商品发货审单
	 * @param params
	 * @returns {*}
	 */
	saleOrderBatchAudit(params = {}) {
		return fetch(`/v2/order/bms/sale_order/audit/batch`, {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新入库单状态为待复核
	 * @param goodsInSubOrderId
	 * @returns {*}
	 */
	updateReCheckStatus(goodsInSubOrderId = '') {
		return fetch(`/v1/order/bms/goods_in_order/update_status_wait_re_check`, {
			params: { goodsInSubOrderId }
		})
	},

	/**
	 * @description 信用免赔
	 * @returns {*}
	 * @param params
	 */
	manualCreditPaFree(params = {}) {
		return fetch(`/v2/order/bms/damage_order/manual_credit_pay_free`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取销售商品-单品类别列表
	 * @returns
	 */
	getItemProductTypeList() {
		return fetch('/v2/order/bms/item_product/type_list')
	},

	/**
	 * @description 查询商品类型对应的单品
	 * @param {*} params
	 * @returns
	 */
	searchItemProductInfo(params = {}) {
		return fetch('/v2/order/bms/item_product/search', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 批量发货
	 * @param {*} params
	 * @returns
	 */
	saleOrderBatchDelivery(params = {}) {
		return fetch('/v2/order/bms/sale_order/delivery/batch', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 导入exl发货
	 * @param {*} params
	 * @returns
	 */
	importDeliveryReadExcel(params = {}) {
		return fetch('/v2/order/bms/sale_order/delivery/read_excel', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取用户书袋点信息
	 * @param {*} userId
	 * @returns
	 */
	getUserBagPoint(userId = '') {
		return fetch('/v2/order/bms/bag_point/get_user_bag_point', {
			params: { userId }
		})
	},

	/**
	 * @description 获取用户书袋点消费明细
	 * @param {*} userId 
	 * @returns 
	 */
	getUserBagPointRecordList(userId = '') {
		return fetch('/v2/order/bms/bag_point/get_user_bag_point_record_list', {
			params: { userId }
		})
	}
}

export default OrderService
